<template>
  <div class="">
    <div class="w-52 h-screen lg:block hidden sticky top-16 bg-navbarColor">
      <div class="
          flex flex-row
          gap-2
          flex-nowrap
          items-center
          pl-5
          pt-5
          bg-lightGray
          shadow-xl
          mb-10
          pb-5
        ">
        <div class="h-3 w-3 rounded-full bg-green-500" />
        <div v-if="getUser" class="flex flex-col flex-nowrap text-white">
          <p class="text-sm font-bold">
            {{ getUser.fullName }}
          </p>
          <p class="text-xs text-gray-300">
            {{ getUser.role }}
          </p>
        </div>
      </div>
      <div v-for="menu in filteredMenu" :key="menu.name">
        <div :class="{
          'gradient shadow-xl': selectedMenu == menu.name,
        }" class="
            flex flex-row
            text-white
            items-center
            gap-4
            border-b-0.5 border-opacity-20
            pl-5
            py-7
            cursor-pointer
            hover:bg-sideBarGray hover:bg-opacity-70
          " @click="changeMenu(menu.name)">
          <img class="h-4" :src="menu.icon" />
          <p class="text-xs font-medium">
            {{ menu.name === 'Transactions' ? 'Mpesa ' + menu.name : menu.name }}
          </p>
        </div>
      </div>
    </div>

    <div :class="`grid-cols-${filteredMenu.length}`" class="
        lg:hidden
        w-full
        h-14
        bg-navbarColor
        fixed
        bottom-0
        z-50
        grid
        items-center
        justify-evenly
      ">
      <div class="h-full" v-for="menu in filteredMenu" :key="menu.name">
        <div :class="{
          'gradient shadow-xl': selectedMenu == menu.name,
        }" class="
            flex flex-col
            text-white
            justify-center
            items-center
            h-full
            p-2
            gap-1
            border-b-0.5 border-opacity-20
            cursor-pointer
            hover:bg-sideBarGray hover:bg-opacity-70
          " @click="changeMenu(menu.name)">
          <img class="h-4" :src="menu.icon" />
          <p class="text-xs text-center font-medium">
            {{ menu.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SideBar",

  data() {
    return {
      selectedMenu: null,
      menus: [
        {
          name: "Dashboard",
          icon: require("@/assets/icons/dashboard.png"),
          visibility: [
            "Company Administrator",
            "Paybill User",
            "LipaLink Administrator",
          ],
        },
        {
          name: "Transactions",
          icon: require("@/assets/icons/list.png"),
          visibility: ["Company Administrator", "Paybill User"],
        },
        {
          name: "I&M Transactions",
          icon: require("@/assets/icons/list.png"),
          visibility: ["Company Administrator", "Paybill User"],
        },
        {
          name: "Shortcodes",
          icon: require("@/assets/icons/hastag.png"),
          visibility: ["Company Administrator", "Paybill User"],
        },
        {
          name: "Users",
          icon: require("@/assets/icons/users.png"),
          visibility: ["Company Administrator", "LipaLink Administrator"],
        },
        {
          name: "Setups",
          icon: require("@/assets/icons/settings.png"),
          visibility: ["Company Administrator", "LipaLink Administrator"],
        },

        {
          name: "Organizations",
          icon: require("@/assets/icons/building.png"),
          visibility: ["LipaLink Administrator"],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
    filteredMenu() {
      var menus = [];
      this.menus.forEach((menu) => {
        if (menu.visibility.includes(this.getUser.role)) {
          menus.push(menu);
        }
      });
      return menus;
    },
  },
  created() {
    this.$router.replace({ name: "dashboard", hash: `#${this.menus[0].name}` });
    this.selectedMenu = this.menus[0].name;
    this.$emit("selectedMenu", this.menus[0].name);
  },
  methods: {
    changeMenu(name) {
      this.selectedMenu = name;
      this.$router.replace({
        name: "dashboard",
        hash: `#${this.selectedMenu}`,
      });
      this.$emit("selectedMenu", name);
    },
    canSeeMenu(visibility) {
      if (visibility.includes(this.getUser.role)) {
        return true;
      }
    },
  },
};
</script>

<style scoped>
.gradient {
  background: rgb(251, 185, 29);
  background: -moz-linear-gradient(90deg,
      rgba(251, 185, 29, 1) 0%,
      rgba(226, 123, 1, 1) 100%);
  background: -webkit-linear-gradient(90deg,
      rgba(251, 185, 29, 1) 0%,
      rgba(226, 123, 1, 1) 100%);
  background: linear-gradient(90deg,
      rgba(251, 185, 29, 1) 0%,
      rgba(226, 123, 1, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbb91d", endColorstr="#e27b01", GradientType=1);
}
</style>