<template>
  <div class="relative">
    <div v-if="loading" class="absolute inset-0 bg-black bg-opacity-10  z-40">
      <loader class="absolute center-h-v" />
    </div>
    <ve-table
      :columns="columns"
      :table-data="tableData"
      :row-style-option="rowStyleOption"
      :expand-option="expandOption"
      row-key-field-name="rowKey"
     
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import loader from "../loader/loader.vue";
import axios from "axios";
export default {
  name: "SmsProviderTable",
  props: ["hasProviderListChanged"],
  components: { loader },
  data() {
    return {
      rowStyleOption: {
        stripe: true,
      },
      expandOption: { 
        trigger: "row",
        render: ({ row }) => {
          return (
            <table class="table-auto w-full p-2">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Operation</th>
                  <th>Route</th>
                </tr>
              </thead>
              <tbody>
                {row.Operations.map((operation) => {
                  return (
                    <tr class="text-center">
                      <td>{operation.Action}</td>
                      <td>{operation.Operation}</td>
                      <td>{operation.Route}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        },
      },
      columns: [
        {
          field: "",
          key: "as",
          type: "expand",
          title: "",
          width: 50,
          align: "center",
        },
        {
          field: "",
          key: "numbering",
          title: "",
          width: 50,
          align: "center",
          renderBodyCell: ({ rowIndex }) => {
            return ++rowIndex;
          },
        },
        {
          field: "Name",
          key: "a",
          title: "Name",
          align: "left",
        },
        {
          field: "RequestUri",
          key: "b",
          title: "Request Uri",
          align: "left",
        },
        {
          field: "Action",
          key: "c",
          title: "View Operations",
          align: "left",
          renderBodyCell: () => {
            return (
              <div>
                <button class="cursor-pointer text-xs bg-buttonColor text-white p-1 rounded">
                  View
                </button>
              </div>
            );
          },
        },
      ],
      tableData: [],
      loading: false,
    };
  },
  watch: {
    hasProviderListChanged: function (val) {
      if (val) {
        this.getProviders();
      }
    },
  },
  computed: {
    ...mapGetters(["getToken"]),
  },
  created() {
    this.getProviders();
  },
  methods: {
    getProviders() {
      this.tableData = [];
      this.loading = true;
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .get(
          `${this.$route.params.organization}/provider/query?sortString=Newest&order=ASC&pagingparametermodel.pageNumber=1&pagingparametermodel._pageSize=20&pagingparametermodel.pageSize=20`,
          options
        )
        .then(
          (response) => {
            this.tableData = response.data.SMSProviders;
            this.tableData.forEach((v, index) => {
              v.rowKey = `${index + ``}`;
            });
            this.loading = false;
          },
          () => {
            this.loading = false;
            //this.error = true;
          }
        );
    },
  },
};
</script>