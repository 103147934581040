<template>
  <div class="text-primaryDark">
    <transition name="fade">
      <div v-if="showEditUser" class="bg-black fixed bg-opacity-70 inset-0 z-50 h-screen w-full">
        <div class="
            fixed
            center-h-v
            rounded-md
            bg-white
            sm:w-2/5
            w-full
            shadow-2xl
          ">
          <div v-if="loading" class="absolute inset-0 bg-black bg-opacity-50">
            <loader class="absolute center-h-v" />
          </div>
          <div class="flex justify-between items-center border-b-1.5 p-3">
            <p class="">Edit User</p>
            <img v-if="!loading" class="
                h-5
                object-contain
                bg-buttonColor
                rounded-md
                cursor-pointer
                hover:shadow-lg
              " src="@/assets/icons/cross-white.png" @click="close()" />
          </div>
          <form @submit.prevent="editUser">
            <div class="p-6">
              <div class="grid grid-cols-2 gap-5">
                <div>
                  <label class="text-sm">Full Name</label>
                  <input v-model="data.FullName" required type="text" placeholder="Full Name" class="
                      p-2
                      mt-2
                      block
                      rounded
                      w-full
                      bg-gray-200
                      focus:bg-white
                    " />
                  <span v-if="modelError && modelError['create.' + 'FullName']" class="text-red-500 text-sm block w-full">
                    {{ modelError["create." + "FullName"][0] }}
                  </span>
                </div>
                <div>
                  <label class="text-sm">Email</label>
                  <input v-model="Email" required disabled type="email" placeholder="Email" class="
                      p-2
                      mt-2
                      rounded
                      block
                      w-full
                      bg-gray-200
                      focus:bg-white
                    " />
                  <span v-if="modelError && modelError['create.' + 'Email']" class="text-red-500 text-sm block w-full">
                    {{ modelError["create." + "Email"][0] }}
                  </span>
                </div>
              </div>
              <div class="grid grid-cols-1 gap-5 mt-6">
                <div>
                  <label class="text-sm">Role</label>
                  <select v-model="data.Role" class="
                      p-2
                      mt-2
                      rounded
                      block
                      w-full
                      bg-gray-200
                      focus:bg-white
                    ">
                    <option v-for="role in roles" :key="role.Id" :value="role.Name">
                      {{ role.Name }}
                    </option>
                  </select>
                  <span v-if="modelError && modelError['create.' + 'Role']" class="text-red-500 text-sm block w-full">
                    {{ modelError["create." + "Role"][0] }}
                  </span>
                </div>
              </div>
              <div class="flex justify-center mt-10">
                <button type="submit" class="bg-buttonColor rounded-md text-white p-2 w-1/2">
                  Edit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      >
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
var qs = require("qs");
import Loader from "../loader/loader.vue";
export default {
  name: "EditUser",
  components: { Loader },
  props: ["showEditUser", "userData"],
  data() {
    return {
      roles: null,
      loading: false,
      Email: null,
      data: {
        FullName: null,
        UserId: null,
        Role: null,
      },
      modelError: {},
    };
  },
  computed: {
    ...mapGetters(["getToken"]),
  },
  watch: {
    userData(val) {
      if (val) {
        this.data.FullName = this.userData.FullName;
        this.Email = this.userData.Email;
        this.data.Role = this.userData.Role;
        this.data.UserId = this.userData.Id;
      }
    },
  },
  created() {
    const options = {
      headers: {
        Authorization: `Bearer ${this.getToken}`,
      },
    };
    var urls = [
      axios.get(`${this.$route.params.organization}/role/request`, options),
    ];
    axios.all(urls).then((response) => {
      console.log(response)
      this.roles = response[0].data;
    });
  },
  methods: {
    close() {
      this.$emit("close");
    },
    editUser() {
      this.loading = true;
      this.modelError = {};
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .put(
          `${this.$route.params.organization}/user/update`,
          qs.stringify(this.data),
          options
        )
        .then(
          () => {
            this.loading = false;
            this.$emit("updateUsersTable");
          },
          (error) => {
            this.loading = false;
            if (
              error.response["data"] &&
              error.response["data"]["ModelState"]
            ) {
              this.modelError = error.response["data"]["ModelState"];
            }
          }
        );
    },
  },
};
</script>

<style scoped>
.center-h-v {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>