<template>
  <div class="text-primaryDark">
    <transition name="fade">
      <div
        v-if="showCreateShortcode"
        class="bg-black fixed bg-opacity-70 inset-0 z-50 h-screen w-full"
      >
        <div
          class="
            fixed
            center-h-v
            rounded-md
            bg-white
            shadow-2xl
            sm:w-2/5
            w-full
          "
        >
          <div v-if="loading" class="absolute inset-0 bg-black bg-opacity-50">
            <loader class="absolute center-h-v" />
          </div>
          <div class="flex justify-between items-center border-b-1.5 p-3">
            <p class="">Create Shortcode</p>
            <img
              v-if="!loading"
              class="
                h-5
                rounded-md
                object-contain
                bg-buttonColor
                cursor-pointer
                hover:shadow-lg
              "
              src="@/assets/icons/cross-white.png"
              @click="close()"
            />
          </div>
          <form @submit.prevent="createShortcode">
            <div class="p-6">
              <div class="grid grid-cols-2 gap-5">
                <div>
                  <label class="text-sm">Business Short Code</label>
                  <input
                    v-model="data.BusinessShortcode"
                    required
                    type="text"
                    class="
                      p-2
                      mt-2
                      block
                      rounded
                      w-full
                      bg-gray-200
                      focus:bg-white
                    "
                  />
                  <span
                    v-if="
                      modelError && modelError['create.' + 'BusinessShortcode']
                    "
                    class="text-red-500 text-sm block w-full"
                  >
                    {{ modelError["create." + "BusinessShortcode"][0] }}
                  </span>
                </div>
                <div>
                  <label class="text-sm">Business Name</label>
                  <input
                    v-model="data.BusinessName"
                    required
                    type="text"
                    class="
                      p-2
                      mt-2
                      block
                      rounded
                      w-full
                      bg-gray-200
                      focus:bg-white
                    "
                  />
                  <span
                    v-if="modelError && modelError['create.' + 'BusinessName']"
                    class="text-red-500 text-sm block w-full"
                  >
                    {{ modelError["create." + "BusinessName"][0] }}
                  </span>
                </div>
              </div>
              <div class="grid grid-cols-2 gap-5 mt-6">
                <div>
                  <label class="text-sm">Consumer Key</label>
                  <input
                    v-model="data.ConsumerKey"
                    required
                    type="text"
                    class="
                      p-2
                      mt-2
                      block
                      rounded
                      w-full
                      bg-gray-200
                      focus:bg-white
                    "
                  />
                  <span
                    v-if="modelError && modelError['create.' + 'ConsumerKey']"
                    class="text-red-500 text-sm block w-full"
                  >
                    {{ modelError["create." + "ConsumerKey"][0] }}
                  </span>
                </div>
                <div>
                  <label class="text-sm">Customer Secret</label>
                  <input
                    v-model="data.ConsumerSecret"
                    required
                    type="text"
                    class="
                      p-2
                      mt-2
                      block
                      rounded
                      w-full
                      bg-gray-200
                      focus:bg-white
                    "
                  />
                  <span
                    v-if="
                      modelError && modelError['create.' + 'ConsumerSecret']
                    "
                    class="text-red-500 text-sm block w-full"
                  >
                    {{ modelError["create." + "ConsumerSecret"][0] }}
                  </span>
                </div>
              </div>
              <div class="grid grid-cols-1 gap-5 mt-6">
                <div>
                  <label class="text-sm">Pass Key</label>
                  <input
                    v-model="data.PassKey"
                    required
                    type="text"
                    class="
                      p-2
                      mt-2
                      block
                      rounded
                      w-full
                      bg-gray-200
                      focus:bg-white
                    "
                  />
                  <span
                    v-if="modelError && modelError['create.' + 'PassKey']"
                    class="text-red-500 text-sm block w-full"
                  >
                    {{ modelError["create." + "PassKey"][0] }}
                  </span>
                </div>
              </div>
              <div class="flex justify-center mt-10">
                <button
                  type="submit"
                  class="bg-buttonColor rounded-md text-white p-2 w-1/2"
                >
                  Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      >
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Loader from "../loader/loader.vue";
var qs = require("qs");
export default {
  name: "CreateShortcode",
  components: { Loader },
  props: ["showCreateShortcode"],
  data() {
    return {
      data: {
        BusinessShortcode: null,
        BusinessName: null,
        ConsumerKey: null,
        ConsumerSecret: null,
        PassKey: null,
      },
      modelError: {},
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getToken"]),
  },

  methods: {
    close() {
      this.$emit("close");
    },
    createShortcode() {
      this.loading = true;
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .post(
          `${this.$route.params.organization}/shortcode/create`,
          qs.stringify(this.data),
          options
        )
        .then(
          () => {
            this.loading = false;
            this.$emit("updateShortcodesTable");
          },
          (error) => {
            this.loading = false;
            if (
              error.response["data"] &&
              error.response["data"]["ModelState"]
            ) {
              this.modelError = error.response["data"]["ModelState"];
            }
          }
        );
    },
  },
};
</script>

<style scoped>
.center-h-v {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>