<template>
  <div class="relative">
    <transition name="fade">
      <p
        v-if="success"
        class="w-full px-5 py-1 text-sm text-white bg-green-400"
      >
        Changes saved
      </p>
    </transition>
    <transition name="fade">
      <p v-if="error" class="w-full px-5 py-1 text-sm text-white bg-red-400">
        An error occurred
      </p>
    </transition>
    <div v-if="loading" class="absolute inset-0 bg-black bg-opacity-10 z-50">
      <loader class="absolute center-h-v" />
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-3">
      <div class="col-span-1 p-5">
        <p class="pb-2 text-sm text-center">SMS preview</p>
        <p id="sms-preview" class="preview text-xs ml-4">
          {{ convertToSMS }}
        </p>
      </div>
      <div class="col-span-2 p-5">
        <p class="pb-2 text-sm">Edit SMS template below</p>
        <p class="text-sm pt-3">
          <i>Example</i><br />
          <span
            class="
              bg-gray-400
              text-white
              cursor-pointer
              hover:bg-buttonColor hover:text-white
            "
          >
            Hi {CustomerName}, we have received a payment of {Amount} with an
            M-Pesa code {MpesaCode} on {PaymentTime}</span
          >
        </p>
        <div class="h-10 flex flex-col justify-center">
          <transition name="fade">
            <p v-if="textCopied" class="bg-green-400 text-xs text-white p-1">
              Text copied to clipboard
            </p>
          </transition>
        </div>
        <textarea
          id="editable"
          ref="editable"
          v-model="templateText"
          rows="5"
          placeholder="Enter SMS  template here"
          class="w-full p-2 rounded-md bg-gray-200 text-sm"
        />
        <div class="flex justify-end my-4">
          <button
            v-if="templateId == null"
            @click="saveSMSTemplate"
            class="
              bg-green-400
              rounded
              hover:shadow-md
              text-white
              p-1.5
              text-xs
            "
          >
            Create templates
          </button>
          <button
            v-else
            @click="updateSMSTemplate"
            class="
              bg-green-400
              rounded
              hover:shadow-md
              text-white
              p-1.5
              text-xs
            "
          >
            Save changes
          </button>
        </div>
        <p class="pb-2 text-xs">
          SMS Variables (<i>Click to copy a variable</i>)
        </p>
        <div class="flex flex-wrap gap-3">
          <span
            @click="copyText(variable.CSharpVariable)"
            v-for="variable in variables"
            :key="variable.CSharpVariable"
            class="
              bg-gray-200
              px-2
              py-1
              hover:bg-buttonColor hover:text-white
              rounded-full
              text-xs
              cursor-pointer
            "
            >{{ variable.CSharpVariable }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var _ = require("lodash");
import axios from "axios";
import { mapGetters } from "vuex";
var qs = require("qs");
import loader from "../loader/loader.vue";
export default {
  name: "SMSTemplate",
  components: { loader },
  data() {
    return {
      templateText: "",
      textCopied: false,
      templateId: null,
      success: false,
      error: false,
      loading: false,
      variables: [],
    };
  },
  computed: {
    ...mapGetters(["getToken"]),
    convertToSMS() {
      const regex = /({[^{]*?)\w(?=\})}/gim;
      const text = this.templateText;
      const new_text = text.replace(regex, (item) => {
        var obj = _.find(this.variables, ["CSharpVariable", item]);
        if (obj != null) {
          return obj.value;
        }
      });
      return new_text;
    },
  },
  created() {
    this.getSMSVariables();
  },
  methods: {
    convertToCSharpTemplate() {
      const regex = /({[^{]*?)\w(?=\})}/gim;
      const text = this.templateText;
      const new_text = text.replace(regex, (item) => {
        var obj = _.find(this.variables, ["CSharpVariable", item]);
        if (obj != null) {
          var csharp = `{${obj.name}}`;
          return csharp;
        }
      });
      return new_text;
    },
    convertSMSTemplate(text) {
      const regex = /({[^{]*?)\w(?=\})}/gim;
      const new_text = text.replace(regex, (item) => {
        var value = item.replace(/[{}]/g, "");
        var obj = _.find(this.variables, ["name", value]);
        if (obj != null) {
          return obj.CSharpVariable;
        }
      });
      return new_text;
    },
    getSMSTemplate() {
      this.loading = true;
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .get(`${this.$route.params.organization}/template/request`, options)
        .then(
          (response) => {
            this.loading = false;
            if (response.data != null) {
              this.templateId = response.data.Id;
              this.templateText = this.convertSMSTemplate(
                response.data.Template
              );
            }
          },
          () => {
            this.loading = false;
          }
        );
    },
    updateSMSTemplate() {
      this.loading = true;
      let data = {
        Id: this.templateId,
        Template: this.convertToCSharpTemplate(),
      };
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .put(
          `${this.$route.params.organization}/template/update`,
          qs.stringify(data),
          options
        )
        .then(
          () => {
            this.loading = false;
            this.success = true;
            setTimeout(() => {
              this.success = false;
            }, 3000);
          },
          () => {
            this.loading = false;
            this.error = true;
            setTimeout(() => {
              this.error = false;
            }, 3000);
          }
        );
    },
    saveSMSTemplate() {
      this.loading = true;
      let data = {
        Template: this.convertToCSharpTemplate(),
      };
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .post(
          `${this.$route.params.organization}/template/create`,
          qs.stringify(data),
          options
        )
        .then(
          () => {
            this.loading = false;
            this.success = true;
            setTimeout(() => {
              this.success = false;
            }, 3000);
          },
          () => {
            this.loading = false;
            this.error = true;
            setTimeout(() => {
              this.error = false;
            }, 3000);
          }
        );
    },
    getSMSVariables() {
      this.loading = true;
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .get(`${this.$route.params.organization}/variable/request`, options)
        .then(
          (response) => {
            response.data.forEach((element) => {
              let csharpVariable = `{${element.Variable}}`;
              var obj = {
                name: element.Index,
                value: element.PlaceHolder,
                CSharpVariable: csharpVariable,
              };
              this.variables.push(obj);
            });
            this.loading = false;
            this.getSMSTemplate();
          },
          () => {
            this.loading = false;
          }
        );
    },
    copyText(text) {
      if (this.textCopied) {
        this.textCopied = false;
      }
      navigator.clipboard.writeText(text);
      this.textCopied = true;
      setTimeout(() => {
        this.textCopied = false;
      }, 3000);
    },
  },
};
</script>

<style scoped>
.center-h-v {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.preview {
  background: #e5e5ea;
  color: black;
  align-self: flex-start;
}
.preview:before {
  left: -7px;
  width: 20px;
  background-color: #e5e5ea;
  border-bottom-right-radius: 16px 14px;
}
.preview:after {
  left: -26px;
  width: 26px;
  background-color: white;
  border-bottom-right-radius: 10px;
}
#sms-preview {
  max-width: 255px;
  word-wrap: break-word;
  margin-bottom: 12px;
  line-height: 24px;
  position: relative;
  padding: 20px 20px;
  border-radius: 25px;
}

#sms-preview:before,
#sms-preview:after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 25px;
}
</style>