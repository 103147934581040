<template>
  <div class="pt-2 relative">
    <div v-if="loading" class="absolute inset-0 bg-black bg-opacity-10 z-40">
      <loader class="absolute center-h-v" />
    </div>
    <div class="flex gap-2 pb-2">
      <ve-pagination v-if="!loading" :page-size="pageInformation.pageSize" :total="pageInformation.totalCount"
        :layout="['sizer']" :pageSizeOption="[1, 5, 10, 20]" @on-page-size-change="pageSizeChange" />
      <form v-if="!loading" @submit.prevent="search"
        class="border-0.5 flex items-center justify-between text-xs border-inputBorder p-1 mr-2 rounded-sm">
        <input v-model="searchTag" class="focus:outline-none" placeholder="search" />
        <button type="submit">
          <img class="h-4 object-contain cursor-pointer" src="@/assets/icons/search.png" />
        </button>
      </form>
    </div>
    <ve-table class="p-3" :columns="columns" :table-data="tableData" :row-style-option="rowStyleOption" :border-x="true"
      :border-y="true" :sort-option="sortOption" :columnHiddenOption="columnHiddenOption" ref="tableRef" />

    <div class="p-2 text-center">
      <ve-pagination v-if="!loading" :total="pageInformation.totalCount" :page-index="pageInformation.currentPage"
        :page-size="pageInformation.pageSize" :layout="['prev', 'pager', 'next']"
        @on-page-number-change="pageNumberChange" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import loader from "../loader/loader.vue";
var qs = require("qs");
export default {
  name: "ShortcodesTable",
  components: { loader },
  props: ["hasShortcodesListChanged"],
  data() {
    return {
      rowStyleOption: {
        stripe: true,
      },
      searchTag: "",
      pageIndex: 1,
      pageSize: 10,
      sortOption: {
        sortChange: (params) => {
          this.sortData(params);
        },
      },
      columnHiddenOption: {
        // default hidden column keys

        defaultHiddenColumnKeys: [""],
      },
      columns: [
        {
          field: "",
          key: "numbering",
          title: "#",
          width: 50,
          align: "center",
          renderBodyCell: ({ rowIndex }) => {
            return ++rowIndex;
          },
        },
        {
          field: "Shortcode",
          key: "a",
          title: "Shortcode",
          align: "left",
          sortBy: "",
        },
        {
          field: "ShortcodeName",
          key: "b",
          title: "Shortcode Name",
          align: "left",
          sortBy: "",
        },
        {
          field: "Organization",
          key: "c",
          title: "Organization",
          align: "left",
          sortBy: "",
        },
        {
          field: "Balance",
          key: "d",
          title: "Balance",
          align: "left",
          sortBy: "",
          renderBodyCell: ({ row, column }) => {
            const value = row[column.field];
            return `KES ${Number(value).toLocaleString()}`;
          },
        },
        {
          field: "Registered",
          key: "e",
          title: "Callback Registered",
          align: "center",
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }) => {
            const value = row[column.field];
            return value ? (
              <p
                title="This shortcode has a registered callback URL"
                class="cursor-pointer text-center"
              >
                True
              </p>
            ) : (
              <div on-click={() => this.registerCallback(row, rowIndex)}>
                <button
                  title="This shortcode does not have a registered callback URL"
                  class="cursor-pointer text-xs bg-buttonColor text-white p-1 rounded"
                >
                  Register Callback
                </button>
              </div>
            );
          },
        },
      ],
      tableData: [],
      loading: false,
      registeringCallback: false,
      pageInformation: null,
      sortObj: {
        column: "shortcode",
        order: "desc",
      },
    };
  },
  computed: {
    ...mapGetters(["getToken", "getUser"]),
  },
  watch: {
    hasShortcodesListChanged: function (val) {
      if (val) {
        this.getShortcodes(
          this.searchTag,
          this.pageIndex,
          this.pageSize,
          this.sortObj
        );
      }
    },
  },
  created() {
    this.getShortcodes(
      this.searchTag,
      this.pageIndex,
      this.pageSize,
      this.sortObj
    );
    if (this.getUser.role == "Paybill User") {
      this.columnHiddenOption.defaultHiddenColumnKeys = ["e"];
    }
  },
  methods: {
    sortData(params) {
      const keys = Object.keys(params);
      let sortObj;
      const isEmpty = Object.values(params).every(
        (x) => x === null || x === ""
      );
      if (!isEmpty) {
        keys.forEach((key) => {
          if (params[key] !== "") {
            sortObj = {
              column: key.toLowerCase(),
              order: params[key],
            };
          }
        });
      } else {
        sortObj = {
          column: "shortcode",
          order: "asc",
        };
      }

      this.getShortcodes(
        this.searchTag,
        this.pageIndex,
        this.pageSize,
        sortObj
      );
    },
    search() {
      this.getShortcodes(
        this.searchTag,
        this.pageIndex,
        this.pageSize,
        this.sortObj
      );
    },
    pageSizeChange(size) {
      this.pageIndex = 1;
      this.getShortcodes(
        this.searchTag,
        this.pageInformation.pageNumber,
        size,
        this.sortObj
      );
      this.pageSize = size;
    },
    pageNumberChange(number) {
      this.getShortcodes(
        this.searchTag,
        number,
        this.pageInformation.pageSize,
        this.sortObj
      );
      this.pageIndex = number;
    },
    edit(row) {
      this.$emit("editShortcode", row);
    },
    registerCallback(row, rowIndex) {
      this.loading = true;
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      var data = {
        Shortcode: row.Shortcode,
      };
      axios
        .post(
          `${this.$route.params.organization}/mpesa/c2b/registerurl`,
          qs.stringify(data),
          options
        )
        .then(
          () => {
            this.loading = false;
            this.$emit("callbackRegistration", true);

            this.tableData[rowIndex].Registercallback = "Registered";
          },
          (error) => {
            this.loading = false;
            console.log(error.response);
            this.$emit("callbackRegistration", false, { message: error.response.data.Message, reason: error.response.statusText });

            //this.$emit("errorMessage", error)
          }
        );
    },
    getShortcodes(searchTag, pageNumber, pageSize, sortObj) {
      this.tableData = [];
      this.loading = true;
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .get(
          `${this.$route.params.organization}/shortcode/query?sortString=${sortObj.column}&order=${sortObj.order}&searchString=${searchTag}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
          options
        )
        .then(
          (response) => {
            console.log(response.data.Shortcodes)
            response.data.Shortcodes.forEach((shortcode) => {
              this.tableData.push(shortcode);
            });
            this.pageInformation = response.data.PageInformation;
            this.loading = false;
          },
          () => {
            this.loading = false;
            //this.error = true;
          }
        );
    },
  },
};
</script>
