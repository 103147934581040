<template>
  <div class="text-primaryDark">
    <transition name="fade">
      <div
        v-if="showCreateOrganization"
        class="bg-black fixed bg-opacity-70 inset-0 z-50 h-screen w-full"
      >
        <div class="fixed center-h-v bg-white shadow-2xl sm:w-1/2 w-full">
          <div v-if="loading" class="absolute inset-0 bg-black bg-opacity-50">
            <loader class="absolute center-h-v" />
          </div>
          <div class="flex justify-between items-center border-b-1.5 p-3">
            <p class="text-lg">Create Organization</p>
            <img
              v-if="!loading"
              class="
                h-6
                object-contain
                bg-primary
                cursor-pointer
                hover:shadow-lg
              "
              src="@/assets/icons/cross-white.png"
              @click="close()"
            />
          </div>
          <form @submit.prevent="createOrganization">
            <div class="p-6">
              <div class="grid grid-cols-2 gap-5">
                <div>
                  <label class="text-sm">Organization Name</label>
                  <input
                    v-model="data.OrganizationName"
                    required
                    type="text"
                    placeholder="Organization Name"
                    class="p-2 mt-2 block w-full bg-gray-200 focus:bg-white"
                  />
                  <span
                    v-if="
                      modelError && modelError['create.' + 'OrganizationName']
                    "
                    class="text-red-500 text-sm block w-full"
                  >
                    {{ modelError["create." + "OrganizationName"][0] }}
                  </span>
                </div>
                <div>
                  <label class="text-sm">Organization Address</label>
                  <input
                    v-model="data.OrganizationAddress"
                    required
                    type="text"
                    placeholder="Organization Address"
                    class="p-2 mt-2 block w-full bg-gray-200 focus:bg-white"
                  />
                  <span
                    v-if="
                      modelError &&
                      modelError['create.' + 'OrganizationAddress']
                    "
                    class="text-red-500 text-sm block w-full"
                  >
                    {{ modelError["create." + "OrganizationAddress"][0] }}
                  </span>
                </div>
              </div>
              <!-- <div class="grid grid-cols-2 gap-5 mt-6">
                <div>
                  <label class="text-sm">Send Acknowledgment SMS</label>
                  <input
                    v-model="data.SendAcknowledgmentSMS"
                    required
                    type="checkbox"
                    id="SendAcknowledgementSMS"
                    name="SendAcknowledgementSMS"
                    placeholder="Send Acknowledgment SMS"
                    class="p-2 mt-2 block bg-gray-200 focus:bg-white"
                  />
                  <span
                    v-if="
                      modelError &&
                      modelError['create.' + 'SendAcknowledgmentSMS']
                    "
                    class="text-red-500 text-sm block w-full"
                  >
                    {{ modelError["create." + "SendAcknowledgmentSMS"][0] }}
                  </span>
                </div>
                <div>
                  <label class="text-sm">Sync To ERP</label>
                  <input
                    v-model="data.SyncToERP"
                    required
                    type="checkbox"
                    id="SyncToERP"
                    name="SyncToERP"
                    placeholder="Sync To ERP"
                    class="p-2 mt-2 block bg-gray-200 focus:bg-white"
                  />
                  <span
                    v-if="modelError && modelError['create.' + 'SyncToERP']"
                    class="text-red-500 text-sm block w-full"
                  >
                    {{ modelError["create." + "SyncToERP"][0] }}
                  </span>
                </div>
              </div> -->
              <div class="grid grid-cols-1 gap-5 mt-6">
                <div>
                  <label class="text-sm">Organization Logo</label>
                  <input
                    required
                    type="file"
                    ref="file"
                    @change="onFileChange"
                    placeholder="Organization Logo"
                    class="p-2 mt-2 block w-full bg-gray-200 focus:bg-white"
                  />
                  <span
                    v-if="modelError && modelError['create.' + 'PassKey']"
                    class="text-red-500 text-sm block w-full"
                  >
                    {{ modelError["create." + "PassKey"][0] }}
                  </span>
                </div>
              </div>
              <div class="flex justify-center mt-10">
                <button type="submit" class="bg-primary text-white p-2 w-1/2">
                  Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      >
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Loader from "../loader/loader.vue";
// var qs = require("qs");
export default {
  name: "CreateOrganization",
  components: { Loader },
  props: ["showCreateOrganization"],
  data() {
    return {
      data: {
        OrganizationName: null,
        OrganizationAddress: null,
        SendAcknowledgmentSMS: false,
        SyncToERP: false,
        logo: null,
      },
      modelError: {},
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getToken"]),
  },

  methods: {
    onFileChange() {
      this.data.logo = this.$refs.file.files[0];
    },
    createOrganization() {
      this.loading = true;
      const formData = new FormData();
      formData.append("OrganizationName", this.data.OrganizationName);
      formData.append("OrganizationAddress", this.data.OrganizationAddress);
      formData.append("SendAcknowledgmentSMS", this.data.SendAcknowledgmentSMS);
      formData.append("SyncToERP", this.data.SyncToERP);
      formData.append("logo", this.data.logo);
      axios
        .post(`${this.$route.params.organization}/organization/create`, formData, {
          headers: {
            Authorization: "Bearer " + this.getToken,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {

          this.loading = false;
          this.$emit("updateOrganizationsTable", response.data);
        })
        .catch((error) => {
          this.loading = false;
          if (error.response["data"] && error.response["data"]["ModelState"]) {
            this.modelError = error.response["data"]["ModelState"];
          }
        });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.center-h-v {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>