<template>
  <div class="text-primaryDark">
    <transition name="fade">
      <div
        v-if="showEditGateway"
        class="bg-black fixed bg-opacity-70 inset-0 z-50 h-screen w-full"
      >
        <div
          class="
            fixed
            center-h-v
            bg-white
            rounded-md
            sm:w-2/5
            w-full
            shadow-2xl
          "
        >
          <div v-if="loading" class="absolute inset-0 bg-black bg-opacity-50">
            <loader class="absolute center-h-v" />
          </div>
          <div class="flex justify-between items-center border-b-1.5 p-3">
            <p class="">Edit Gateway</p>
            <img
              v-if="!loading"
              class="
                h-5
                object-contain
                bg-buttonColor
                rounded-md
                cursor-pointer
                hover:shadow-lg
              "
              src="@/assets/icons/cross-white.png"
              @click="close()"
            />
          </div>
          <form @submit.prevent="editGateway">
            <div class="p-6">
              <div class="grid grid-cols-2 gap-5">
                <div>
                  <label class="text-sm">SMS Provider No.*</label>
                  <select
                    required
                    v-model="data.SMSProviderNo"
                    class="p-2 mt-2 block w-full bg-gray-200 focus:bg-white"
                  >
                    <option
                      :value="provider.Id"
                      v-for="(provider, index) in providers"
                      :key="index"
                    >
                      {{ provider.Name }}
                    </option>
                  </select>

                  <span
                    v-if="modelError && modelError['create.' + 'SMSProviderNo']"
                    class="text-red-500 text-sm block w-full"
                  >
                    {{ modelError["create." + "SMSProviderNo"][0] }}
                  </span>
                </div>
                <div>
                  <label class="text-sm">Partner Id*</label>
                  <input
                    required
                    v-model="data.PartnerId"
                    type="text"
                    class="p-2 mt-2 block w-full bg-gray-200 focus:bg-white"
                  />
                  <span
                    v-if="modelError && modelError['create.' + 'PartnerId']"
                    class="text-red-500 text-sm block w-full"
                  >
                    {{ modelError["create." + "PartnerId"][0] }}
                  </span>
                </div>
              </div>
              <div class="grid grid-cols-2 gap-5 mt-4">
                <div>
                  <label class="text-sm">Sender Id*</label>
                  <input
                    v-model="data.SenderId"
                    required
                    type="text"
                    class="p-2 mt-2 block w-full bg-gray-200 focus:bg-white"
                  />
                  <span
                    v-if="modelError && modelError['create.' + 'SenderId']"
                    class="text-red-500 text-sm block w-full"
                  >
                    {{ modelError["create." + "SenderId"][0] }}
                  </span>
                </div>
                <div>
                  <label class="text-sm">API Key*</label>
                  <input
                    v-model="data.APIKey"
                    required
                    type="password"
                    class="p-2 mt-2 block w-full bg-gray-200 focus:bg-white"
                  />
                  <span
                    v-if="modelError && modelError['create.' + 'APIKey']"
                    class="text-red-500 text-sm block w-full"
                  >
                    {{ modelError["create." + "APIKey"][0] }}
                  </span>
                </div>
              </div>
              <div class="grid grid-cols-2 gap-5 mt-4">
                <div>
                  <label class="text-sm">Username*</label>
                  <input
                    required
                    v-model="data.Username"
                    type="text"
                    class="p-2 mt-2 block w-full bg-gray-200 focus:bg-white"
                  />
                  <span
                    v-if="modelError && modelError['create.' + 'Username']"
                    class="text-red-500 text-sm block w-full"
                  >
                    {{ modelError["create." + "Username"][0] }}
                  </span>
                </div>
                <div>
                  <label class="text-sm">Password*</label>
                  <input
                    v-model="data.Password"
                    required
                    type="password"
                    class="p-2 mt-2 block w-full bg-gray-200 focus:bg-white"
                  />
                  <span
                    v-if="modelError && modelError['create.' + 'Password']"
                    class="text-red-500 text-sm block w-full"
                  >
                    {{ modelError["create." + "Password"][0] }}
                  </span>
                </div>
              </div>

              <div class="flex justify-center mt-10">
                <button
                  type="submit"
                  class="bg-buttonColor rounded-md text-white p-2 w-1/2"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      >
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
var qs = require("qs");
import Loader from "../loader/loader.vue";
export default {
  name: "EditGateway",
  components: { Loader },
  props: ["showEditGateway", "gatewayData"],
  data() {
    return {
      loading: false,
      data: {
        Id: null,
        SMSProviderNo: null,
        PartnerId: null,
        SenderId: null,
        APIKey: null,
        Username: null,
        Password: null,
      },
      modelError: {},
      providers: [],
    };
  },
  computed: {
    ...mapGetters(["getToken"]),
  },
  created() {
    this.getProviders();
  },
  watch: {
    gatewayData(val) {
      if (val) {
        this.data.Id = this.gatewayData.Id;
        this.data.SMSProviderNo = this.gatewayData.SMSProviderNo;
        this.data.PartnerId = this.gatewayData.PartnerId;
        this.data.SenderId = this.gatewayData.SenderId;
        this.data.APIKey = this.gatewayData.APIKey;
        this.data.Username = this.gatewayData.Username;
        this.data.Password = this.gatewayData.Password;
      }
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getProviders() {
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .get(`${this.$route.params.organization}/provider/request`, options)
        .then((response) => {
          this.providers = response.data;
        });
    },
    editGateway() {
      this.loading = true;
      this.modelError = {};
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .put(
          `${this.$route.params.organization}/gateway/update`,
          qs.stringify(this.data),
          options
        )
        .then(
          () => {
            this.loading = false;
            this.$emit("updateGatewayTable");
          },
          (error) => {
            this.loading = false;
            if (
              error.response["data"] &&
              error.response["data"]["ModelState"]
            ) {
              this.modelError = error.response["data"]["ModelState"];
            }
          }
        );
    },
  },
};
</script>

<style scoped>
.center-h-v {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>