<template>
  <div class="text-primaryDark">
    <transition name="fade">
      <div
        v-if="showPDFViewer"
        class="bg-black fixed bg-opacity-70 inset-0 z-50 h-screen w-full"
      >
        <div class="fixed h-screen center-h-v bg-white w-full shadow-2xl">
          <div
            class="
              flex
              justify-between
              items-center
              border-b-1.5
              p-1
              top-0
              bg-buttonColor
            "
          >
            <p class="text-white">PDF Viewer</p>
            <img
              class="
                h-5
                object-contain
                bg-black
                rounded-md
                cursor-pointer
                hover:shadow-lg
              "
              src="@/assets/icons/cross-white.png"
              @click="close()"
            />
          </div>
          <iframe class="w-full h-full" :src="url"></iframe>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "PDFViewer",
  props: ["showPDFViewer", "url"],
  methods: {
    close() {
      this.$emit("closePDFViewer");
    },
  },
};
</script>