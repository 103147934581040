<template>
    <div class="h-auto m-3 sm:m-5">
        <div class="bg-white rounded-b-md">
            <div class="border-b-0.5 flex justify-between items-center p-3">
                <p class="text-sm font-semibold w-full">I&M Transactions</p>
            </div>

            <div class="flex justify-between items-center pl-0 ml-0 p-2">
                <div class="flex justify-between items-left px-2 py-1">
                    <ve-pagination background @on-page-size-change="handleSizeChange" :pageSizeOption="pageSizes"
                        :page-index="pageIndex" :page-size="pageSize" :layout="['sizer']" :total="currentTableData.length">
                    </ve-pagination>
                    <!-- search -->
                    <input v-model="searchInput" type="search" placeholder="Search"
                        class="border-0.5 w-52 p-0.5 rounded-0 focus:outline-none bg-white rounded-md" />
                    <!-- search -->

                </div>
            </div>

            <div class="px-2 pt- pb-2 relative">
                <transition name="fade">
                    <div v-if="loading" class="inset-0 absolute z-50 bg-white w-full">
                        <loader class="center-h-v" />
                    </div>
                </transition>

                <transition name="fade">
                    <div v-if="get_error" class="p-1 text-center text-sm w-full bg-yellow-200">
                        {{ get_error }}
                    </div>
                </transition>

                <div class="text-xs">
                    <ve-table v-if="searchInput" :columns="columns" :table-data="filteredTableData" sort-order="asc" />

                    <ve-table :columns="columns" :tableData="currentTableData" v-if="!searchInput.length">
                    </ve-table>

                    <p v-if="searchInput.length && filteredTableData.length === 0" class="text-center py-3">
                        No results
                    </p>

                    <p v-if="tableData.length == 0 && !loading" class="p-1 text-center text-sm w-full bg-gray-100">
                        No Transactions
                    </p>

                    <!-- Pagination -->
                    <div class="table-pagination text-center p-2">
                        <ve-pagination background :layout="['prev', 'pager', 'next']" :total="totalCount"
                            :page-size="pageSize" :page-index="pageIndex" @on-page-number-change="handleCurrentChange">
                        </ve-pagination>
                    </div>
                </div>



            </div>
        </div>
    </div>
</template>
  
<script>
import axios from "axios";

import Loader from "@/components/loader/loader.vue";
import moment from 'moment';

import { mapGetters } from "vuex";
export default {
    name: "IAndMTransactions",
    components: {
        Loader,

    },
    data() {
        return {
            loading: false,


            isBlank: false,
            get_error: null,
            searchInput: "",

            // page index
            pageIndex: 1,
            // page size
            pageSize: 10,
            pageSizes: [],
            columns: [
                {
                    field: "",
                    key: "numbering",
                    title: "#",
                    width: 10,
                    align: "center",

                    renderBodyCell: ({ rowIndex }) => {
                        return (this.pageIndex - 1) * this.pageSize + rowIndex + 1;
                    },
                },
                {
                    title: "Trans Ref",
                    field: "TransRef",
                    key: "TransRef",
                    headerAlign: "left",
                    align: "left",

                },
                {
                    title: "Mpesa Ref",
                    field: "MpesaRef",
                    key: "MpesaRef",
                    headerAlign: "left",
                    align: "left",
                },
                {
                    title: "MSISDN",
                    field: "MSISDN",
                    key: "MSISDN",
                    headerAlign: "left",
                    align: "left",
                },
                {
                    title: "Amount",
                    field: "Amount",
                    key: "Amount",
                    headerAlign: "left",
                    align: "left",
                    renderBodyCell: ({ row, column }) => {
                        const value = row[column.field];
                        return `KES ${Number(value).toLocaleString()}`;
                    },
                },
                {
                    title: "Transaction Date",
                    field: "transactionDate",
                    key: "transactionDate",
                    headerAlign: "left",
                    align: "left",
                    renderBodyCell: ({ row }) => row.transactionDate ? moment(row.transactionDate).format('DD/MM/YYYY hh:mm A') : ''
                },
                {
                    title: "Narration",
                    field: "Narration",
                    key: "Narration",
                    headerAlign: "left",
                    align: "left",
                    width: 500
                },
                {
                    title: "Account Ref",
                    field: "AccountRef",
                    key: "AccountRef",
                    headerAlign: "left",
                    align: "left",
                },
                {
                    title: "SortCode",
                    field: "SortCode",
                    key: "SortCode",
                    headerAlign: "left",
                    align: "left",
                },
                {
                    title: "Synced",
                    field: "SyncedToErp",
                    key: "SyncedToErp",
                    headerAlign: "left",
                    align: "left",
                    renderBodyCell: ({ row }) => row.SyncedToErp ? <span style="color: green;">True</span> : <span style="color: brown;">False</span>
                },
                {
                    title: "Date Synced",
                    field: "DateSynced",
                    key: "DateSynced",
                    headerAlign: "left",
                    align: "left",
                    renderBodyCell: ({ row }) => row.DateSynced ? moment(row.DateSynced).format('DD/MM/YYYY hh:mm A') : ''


                },

            ],

            tableData: [],
        };
    },
    computed: {

        ...mapGetters(["getToken"]),

        filteredTableData() {
            if (!this.searchInput) {
                // If no search input is provided, return the full tableData
                return this.currentTableData;
            } else {
                // Filter the currentTableData based on the search input
                const searchInputLower = this.searchInput.toLowerCase();
                return this.tableData.filter((item) => {
                    // Loop through each item's properties and check if they contain the search input
                    for (let key in item) {
                        if (
                            item[key]?.toString().toLowerCase().includes(searchInputLower)
                        ) {
                            return true;
                        }
                    }
                    return false;
                });
            }
        },
        // table data
        currentTableData() {
            const { pageIndex, pageSize } = this;
            return this.tableData.slice(
                (pageIndex - 1) * pageSize,
                pageIndex * pageSize
            );
        },
        // total count
        totalCount() {
            return this.tableData.length;
        },
    },
    created() {
        this.getIAndTransactions(), this.setPageSizes();
    },

    methods: {
        // exportTemplate() {
        //     const ws = utils.json_to_sheet(this.tableDataTemplate);
        //     const wb = utils.book_new();
        //     utils.book_append_sheet(wb, ws, "Data");
        //     writeFileXLSX(wb, "BulkUploadTemplate.xlsx");
        // },
        handleSizeChange(newPageSize) {
            this.pageSize = newPageSize;
        },
        handleCurrentChange(currentPage) {
            this.pageIndex = currentPage;
        },
        setPageSizes() {
            // Generate the page sizes array using a loop
            for (let i = 1; i <= 10; i++) {
                this.pageSizes.push(this.pageSize * i);
            }
        },
        // page number change
        pageNumberChange(pageIndex) {
            this.pageIndex = pageIndex;
        },

        // page size change
        pageSizeChange(pageSize) {
            this.pageIndex = 1;
            this.pageSize = pageSize;
        },

        // /{organization}/setup/claim/workflow/v1/request
        getIAndTransactions() {
            this.loading = true;
            const options = {
                headers: {
                    Authorization: `Bearer ${this.getToken}`,
                },
            };
            axios
                .get(
                    'https://api.lipalink.com/glacierproductsltd/imconnect/v1/transactions',

                    options
                )
                .then(
                    (response) => {

                        this.create_success = response.data.StatusMessage;
                        this.tableData = response.data.Data;
                        console.log(this.tableData);
                        this.loading = false;

                    },
                    (error) => {
                        this.loading = false;
                        this.get_error = error.message;

                        setTimeout(() => {
                            this.showEditError = false;
                        }, 2000);
                    }
                );
        },

    },
};
</script>
  