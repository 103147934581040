<template>
  <div>
    <transition name="fade">
      <div v-if="loading">
        <div class="grid sm:grid-cols-4  grid-cols-2 p-5 gap-5">
          <div v-for="item in 4" :key="item" class="h-32 placeholderLoader rounded-lg"></div>
        </div>
      </div>
      <div v-else class="grid sm:grid-cols-3 md:grid-cols-6  grid-cols-2 p-5 gap-2">
        <div v-for="(tab, index) in tabs" :key="index">
          <!-- <router-link :key="tab.route" :to="tab.route"> -->
          <div @click="goToPage(tab.name)" class="rounded-lg p-4 gradient text-white h-36 ">
            <div class="pb-8 flex justify-between flex-wrap gap-1 items-center">
              <p class="text-sm font-semibold">
                {{ tab.name }}
              </p>
              <p class="
                  text-sm
                  bg-white
                  text-black
                  p-0.5
                  font-semibold
                  rounded
                  opacity-50
                ">
                {{ tab.desc }}
              </p>
            </div>
            <div class="flex justify-between  flex-wrap items-end">
              <div>
                <span class="text-sm">{{ tab.nameType }}</span>
                <p class="text-sm font-medium">
                  {{ Number(tab.number).toLocaleString() }}
                </p>
              </div>
              <div class="h-9 w-9  rounded-full bg-white p-3 opacity-80 shadow-lg">
                <img class="h-full w-full object-contain" :src="tab.icon" />
              </div>
            </div>
          </div>
          <!-- </router-link> -->
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "Analytics",
  data() {
    return {
      loading: false,
      tabs: [
        {
          name: "Mpesa Revenue",
          desc: "Total",
          icon: require("@/assets/icons/dollar.png"),
          nameType: "KES",
          number: null,
          route: ''
        },
        {
          name: "Mpesa Transactions",
          desc: "Total",
          icon: require("@/assets/icons/chart.png"),
          nameType: "Count",
          number: null,
          route: '#Transactions'
        },
        {
          name: "I&M Revenue",
          desc: "Total",
          icon: require("@/assets/icons/dollar.png"),
          nameType: "KES",
          number: null,
          route: ''
        },

        {
          name: "I&M Transactions",
          desc: "Total",
          icon: require("@/assets/icons/chart.png"),
          nameType: "Count",
          number: null,
          route: '#I&M Transactions'
        },

        {
          name: "Shortcodes",
          desc: "Total",
          icon: require("@/assets/icons/receipt.png"),
          nameType: "Count",
          number: null,
          route: '#Shortcodes'
        },
        {
          name: "Users",
          desc: "Total",
          icon: require("@/assets/icons/user-w.png"),
          nameType: "Count",
          number: null,
          route: '#Users'
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getToken"]),
  },
  created() {
    this.loading = true;
    const options = {
      headers: {
        Authorization: `Bearer ${this.getToken}`,
      },
    };
    axios.get(`${this.$route.params.organization}/dashboard/info`, options).then(
      (response) => {
        this.tabs[0].number = response.data.Revenue;
        this.tabs[1].number = response.data.Transactions;
        this.tabs[2].number = response.data.IMRevenue;
        this.tabs[3].number = response.data.IMTransactions;
        this.tabs[4].number = response.data.Shortcodes;
        this.tabs[5].number = response.data.Users;
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  },
  methods: {
    goToPage(name) {
      switch (name) {
        case "Transactions":
          break;

        default:
          break;
      }
    },

  },
};
</script>

<style scoped>
.gradient {
  background: rgb(251, 185, 29);
  background: -moz-linear-gradient(90deg,
      rgba(251, 185, 29, 1) 0%,
      rgba(226, 123, 1, 1) 100%);
  background: -webkit-linear-gradient(90deg,
      rgba(251, 185, 29, 1) 0%,
      rgba(226, 123, 1, 1) 100%);
  background: linear-gradient(90deg,
      rgba(251, 185, 29, 1) 0%,
      rgba(226, 123, 1, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbb91d", endColorstr="#e27b01", GradientType=1);
}
</style>