<template>
  <div class="m-1 sm:m-5 shadow h-auto bg-white rounded">
    <div class="flex justify-between items-center border-b-0.5 px-2 pt-3 pb-2">
      <p class="font-medium">Organizations</p>
      <!-- <button
        v-if="getUser.role == 'LipaLink Administrator'"
        class="bg-buttonColor rounded hover:shadow-md text-white p-1 text-sm"
        @click="openCreateOrganization()"
      >
        Create Organization
      </button> -->
    </div>
    <create-organization :show-create-organization="showCreateOrganization" @close="showCreateOrganization = false"
      @updateOrganizationsTable="updateOrganizationsTableHandler" />
    <edit-organization :show-edit-organization="showEditOrganization" :organization-data="editOrganizationData"
      @close="showEditOrganization = false"
      @updateOrganizationsTable="updateOrganizationTableHandlerOnEdit"></edit-organization>

    <review-organization @close="showReviewOrganization = false" @approved="updateOrganizationTableHandlerOnApproved"
      :showReviewOrganization="showReviewOrganization"
      :reviewOraganizationData="reviewOraganizationData"></review-organization>
    <div>
      <transition name="fade">
        <div v-if="showSuccess" class="p-1 text-center w-full bg-green-200">
          The organization has been created
        </div>
      </transition>
      <transition name="fade">
        <div v-if="showApprovalSuccess" class="p-1 text-center w-full bg-green-200">
          The organization has been approved and an email has been sent to the
          company admin
        </div>
      </transition>
      <transition name="fade">
        <div v-if="showEditSuccess" class="p-1 text-center w-full bg-green-200">
          The organization has been edited successfully
        </div>
      </transition>

      <organizations-table :has-organizations-list-changed="hasOrganizationsListChanged"
        @editOrganization="handleOrganizationEdit" @openReview="handleReviewOrganization" />
    </div>
  </div>
</template>

<script>
import CreateOrganization from "../components/organizations/createOrganizations.vue";
import OrganizationsTable from "../components/organizations/organizationsTable.vue";
import EditOrganization from "../components/organizations/editOrganization.vue";
import ReviewOrganization from "../components/organizations/reviewOrganization.vue";
import { mapGetters } from "vuex";
export default {
  name: "Organizations",
  metaInfo: {
    title: "LipaLink | Organizations",
  },
  components: {
    CreateOrganization,
    OrganizationsTable,
    EditOrganization,
    ReviewOrganization,
  },
  data() {
    return {
      showCreateOrganization: false,
      showSuccess: false,
      showEditOrganization: false,
      showEditSuccess: false,
      hasOrganizationsListChanged: false,
      editOrganizationData: null,
      showReviewOrganization: false,
      reviewOraganizationData: null,
      showApprovalSuccess: false,
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    updateOrganizationTableHandlerOnApproved() {
      this.showReviewOrganization = false;
      this.hasOrganizationsListChanged = true;
      this.showApprovalSuccess = true;
      setTimeout(() => {
        this.showApprovalSuccess = false;
      }, 3000);
    },
    handleReviewOrganization(organizationData) {
      this.reviewOraganizationData = organizationData;
      this.showReviewOrganization = true;
    },
    openCreateOrganization() {
      this.showCreateOrganization = true;
    },
    updateOrganizationsTableHandler() {
      this.showCreateOrganization = false;
      this.hasOrganizationsListChanged = true;
      this.showSuccess = true;
      setTimeout(() => {
        this.showSuccess = false;
      }, 3000);
    },

    handleOrganizationEdit(organization) {
      this.showEditOrganization = true;
      this.editOrganizationData = organization;
    },

    updateOrganizationTableHandlerOnEdit() {
      this.showEditOrganization = false;
      this.hasOrganizationsListChanged = true;
      this.showEditSuccess = true;
      setTimeout(() => {
        this.showEditSuccess = false;
      }, 3000);
    },
  },
};
</script>