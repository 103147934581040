<template>
  <div class="text-primaryDark">
    <transition name="fade">
      <div
        v-if="showAssignedShortcodes"
        class="bg-black fixed bg-opacity-70 inset-0 z-50 h-screen w-full"
      >
        <div class="fixed center-h-v bg-white sm:w-1/2 w-full h-3/4 shadow-2xl rounded-md">
          <div v-if="loading" class="absolute inset-0 bg-black bg-opacity-50">
            <loader class="absolute center-h-v" />
          </div>
          <div class="flex justify-between items-center border-b-1.5 p-3">
            <p class="">Assign shortcodes to user</p>
            <img
              v-if="!loading"
              class="
                h-5
                object-contain
                bg-buttonColor rounded-md
                cursor-pointer
                hover:shadow-lg
              "
              src="@/assets/icons/cross-white.png"
              @click="close()"
            />
          </div>
          <div class="grid grid-cols-2 divide-x-1.5 h-3/4">
            <div class="p-4">
              <div class="border-b-1.5 pb-3 text-sm">
                <p>Name: {{ currentRowData.FullName }}</p>
                <p>Email: {{ currentRowData.Email }}</p>
                <p>Organization: {{ currentRowData.Organization }}</p>
                <p>Role: {{ currentRowData.Role }}</p>
              </div>
              <div>
                <p class="font-medium py-4">Assigned Shortcodes</p>
                <div
                  v-for="shortcode in currentRowData.Shortcodes"
                  :key="shortcode.ShortcodeId"
                >
                  <div class="flex justify-between my-2 bg-gray-100">
                    <p>
                      {{ shortcode.ShortcodeName }} - {{ shortcode.Shortcode }}
                    </p>
                    <button
                      class="
                        bg-primaryDark
                        p-1
                        cursor-pointer
                        flex
                        gap-1
                        text-sm
                        items-center
                        text-white
                      "
                      @click="
                        unassignShortcode(
                          currentRowData.Id,
                          shortcode.ShortcodeId
                        )
                      "
                    >
                      <img class="h-3" src="@/assets/icons/trash.png" />
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-4">
              <p class="font-medium pb-3">Shortcodes</p>
              <div v-for="shortcode in availableShortcodes" :key="shortcode.Id">
                <div class="flex gap-0 justify-between my-2 bg-gray-100">
                  <p>
                    {{ shortcode.ShortcodeName }} - {{ shortcode.Shortcode }}
                  </p>
                  <button
                    v-if="!isShortcodeAssigned(shortcode.Shortcode)"
                    class="
                      bg-primaryDark
                      p-1
                      cursor-pointer
                      flex
                      gap-1
                      text-sm
                      items-center
                      text-white
                      hover:shadow-sm
                    "
                    @click="
                      assignShortcode(
                        currentRowData.Id,
                        shortcode.Id,
                        shortcode.Shortcode,
                        shortcode.ShortcodeName
                      )
                    "
                  >
                    <img class="h-3" src="@/assets/icons/add.png" />
                    Assign
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      >
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Loader from "../loader/loader.vue";
var qs = require("qs");
export default {
  name: "AssignedShortcodes",
  components: { Loader },
  props: ["showAssignedShortcodes", "currentRowData"],
  data() {
    return {
      loading: false,
      availableShortcodes: [],
    };
  },
  computed: {
    ...mapGetters(["getToken", "getUser"]),
  },
  created() {
    if (this.getUser.role !== "LipaLink Administrator") {
      this.getShortcodes();
    }
  },

  methods: {
    isShortcodeAssigned(shortcode) {
      if (
        this.currentRowData.Shortcodes.some((st) => st.Shortcode == shortcode)
      ) {
        return true;
      }
    },
    close() {
      this.$emit("close");
    },
    getShortcodes() {
      this.loading = true;
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .get(
          `${this.$route.params.organization}/shortcode/query?sortString=shortcode&order=desc&pageNumber=1&pageSize=100`,
          options
        )
        .then(
          (response) => {
            response.data.Shortcodes.forEach((shortcode) => {
              this.availableShortcodes.push(shortcode);
            });
            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
    },
    assignShortcode(userId, shortcodeId, shortcode, ShortcodeName) {
      this.loading = true;
      var data = {
        UserId: userId,
        ShortcodeId: [shortcodeId],
      };
      var shortcodeData = {
        ShortcodeId: shortcodeId,
        Shortcode: shortcode,
        ShortcodeName: ShortcodeName,
      };
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios.post(`${this.$route.params.organization}/shortcode/assign`, qs.stringify(data), options).then(
        () => {
          this.currentRowData.Shortcodes.push(shortcodeData);
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    },
    unassignShortcode(userId, shortcodeId) {
      this.loading = true;
      var data = {
        UserId: userId,
        ShortcodeId: shortcodeId,
      };
      axios
        .delete(`${this.$route.params.organization}/shortcode/unassign`, {
          headers: {
            Authorization: `Bearer ${this.getToken}`,
          },
          data: data,
        })
        .then(
          () => {
            var removeIndex = this.currentRowData.Shortcodes.map(function (
              item
            ) {
              return item.ShortcodeId;
            }).indexOf(shortcodeId);

            this.currentRowData.Shortcodes.splice(removeIndex, 1);
            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
    },
  },
};
</script>

<style scoped>
.center-h-v {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>