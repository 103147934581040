<template>
  <div>
    <analytics class="sm:mt-6 mt-0" />
    <graph />
  </div>
</template>

<script>
import Analytics from "../components/dashboard/analytics.vue";
import Graph from "../components/dashboard/graph.vue";
export default {
  name: "Dashboard",
  components: { Analytics, Graph },
  metaInfo: {
    title: "LipaLink | Dashboard",
  },
};
</script>