<template>
  <div class="text-primaryDark">
    <transition name="fade">
      <div
        v-if="showReviewOrganization"
        class="bg-black fixed bg-opacity-70 inset-0 z-50 h-screen w-full"
      >
        <div
          class="
            fixed
            right-0
            overflow-y-auto
            bg-white
            shadow-2xl
            sm:w-1/2
            w-full
            overflow-x-hidden
            h-screen
          "
        >
          <div
            v-if="loading"
            class="absolute inset-0 z-50 bg-black bg-opacity-50"
          >
            <loader class="absolute center-h-v" />
          </div>
          <div
            class="
              flex
              justify-between
              items-center
              border-b-1.5
              p-3
              sticky
              top-0
              bg-white
            "
          >
            <p class="">Review Organization</p>
            <img
              v-if="!loading"
              class="
                h-5
                object-contain
                bg-buttonColor
                rounded-md
                cursor-pointer
                hover:shadow-lg
              "
              src="@/assets/icons/cross-white.png"
              @click="close()"
            />
          </div>
          <div class="p-3">
            <transition name="fade">
              <div
                v-if="error"
                class="
                  text-xs
                  border-1.5 border-red-400
                  mb-6
                  flex
                  items-center
                  p-2
                  gap-4
                  w-full
                "
              >
                <img class="h-5" src="@/assets/icons/cross.png" />
                <p class="font-bold">{{ error }}</p>
              </div>
            </transition>
            <div class="px-5">
              <img
                class="h-10 sm:h-14 object-contain"
                :src="organizationData.logo"
              />
            </div>
            <div class="grid grid-cols-2 gap-x-5 gap-y-8 pt-8 px-5">
              <div>
                <p class="text-xs font-semibold">Organization Name</p>
                <p>{{ organizationData.OrganizationName }}</p>
              </div>
              <div>
                <p class="text-xs font-semibold">Organization Address</p>
                <p>{{ organizationData.OrganizationAddress }}</p>
              </div>
              <div>
                <p class="text-xs font-semibold">Contact Email</p>
                <p>{{ organizationData.Email }}</p>
              </div>
              <div>
                <p class="text-xs font-semibold">Contact Phone No.</p>
                <p>{{ organizationData.Contact }}</p>
              </div>

              <div class="flex flex-col gap-2 place-items-start">
                <img
                  class="h-5 cursor-pointer object-contain"
                  src="@/assets/icons/document.png"
                />
                <p
                  @click="openPDFViewer(organizationData.BusinessPermit)"
                  class="text-buttonColor text-sm cursor-pointer"
                >
                  Business Permit &#10064;
                </p>
              </div>
              <div class="flex flex-col gap-2 place-items-start">
                <img
                  class="h-5 cursor-pointer object-contain"
                  src="@/assets/icons/document.png"
                />
                <p
                  class="text-buttonColor text-sm cursor-pointer"
                  @click="openPDFViewer(organizationData.MPesaAcknowledgment)"
                >
                  Mpesa Acknowledgement &#10064;
                </p>
              </div>
              <div class="flex flex-col gap-2 place-items-start">
                <img
                  class="h-5 cursor-pointer object-contain"
                  src="@/assets/icons/document.png"
                />
                <p
                  @click="openPDFViewer(organizationData.CR12)"
                  class="text-buttonColor text-sm cursor-pointer"
                >
                  CRI2 &#10064;
                </p>
              </div>
              <div class="flex flex-col gap-2 place-items-start">
                <img
                  class="h-5 cursor-pointer object-contain"
                  src="@/assets/icons/document.png"
                />
                <p
                  class="text-buttonColor text-sm cursor-pointer"
                  @click="openPDFViewer(organizationData.CompanyRegistration)"
                >
                  Company Registration &#10064;
                </p>
              </div>
            </div>
            <div class="p-5">
              <p class="text-sm font-semibold">Personalized login page</p>
              
              <a
                target="_blank"
                class="text-blue-500 text-sm cursor-pointer my-4"
                v-bind:href="personalizeUrl"
                >{{ personalizeUrl }}</a
              >
            </div>
            <pdf-viewer
              :showPDFViewer="showPDFViewer"
              :url="pdfToView"
              @closePDFViewer="showPDFViewer = false"
            ></pdf-viewer>
            <div
              class="flex justify-center mt-5"
              v-if="organizationData.Status == 'Pending'"
            >
              <button
                @click="approve"
                class="bg-buttonColor p-2 w-1/2 rounded text-white"
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      </div>
      >
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../loader/loader.vue";
import { mapGetters } from "vuex";
import PdfViewer from "../pdf/viewer.vue";
export default {
  name: "ReviewOrganization",
  components: { Loader, PdfViewer },
  props: ["showReviewOrganization", "reviewOraganizationData"],
  data() {
    return {
      loading: false,
      error: null,
      organizationData: null,
      showPDFViewer: false,
      pdfToView: null,
      approveData: { OrganizationId: null, OrganizationUsername: null },
    };
  },
  watch: {
    reviewOraganizationData(val) {
      if (val) {
        this.organizationData = this.reviewOraganizationData;
        this.approveData.OrganizationId = this.reviewOraganizationData.Id;
        this.approveData.OrganizationUsername =
          this.reviewOraganizationData.Username;
      }
    },
  },
  computed: {
    ...mapGetters(["getToken"]),
    personalizeUrl() {
      return `https://app.lipalink.com/${this.approveData.OrganizationUsername}/login`;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    openPDFViewer(url) {
      this.showPDFViewer = true;
      this.pdfToView = url;
    },
    approve() {
      this.loading = true;
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      var data = {
        OrganizationId: this.approveData.OrganizationId,
      };
      axios
        .post(
          `${this.$route.params.organization}/organization/approve`,
          data,
          options
        )
        .then((res) => {
          this.loading = false;
          this.$emit("approved", res);
        })
        .catch((err) => {
          this.loading = false;
          this.error = err;
          console.log(err);
        });
    },
  },
};
</script>