<template>
  <div class="text-primaryDark">
    <transition name="fade">
      <div
        v-if="showcreateProvider"
        class="bg-black fixed bg-opacity-70 inset-0 z-50 h-screen w-full"
      >
        <div
          class="fixed overflow-y-scroll max-h-screen center-h-v bg-white rounded-md sm:w-2/5 w-full shadow-2xl"
        >
          <div
            v-if="loading"
            class="absolute inset-0 bg-black bg-opacity-50 z-50"
          >
            <loader class="absolute center-h-v" />
          </div>
          <div
            class="flex justify-between items-center border-b-1.5 p-3 sticky top-0 bg-white"
          >
            <p class="text-base">Create Service Provider</p>
            <img
              v-if="!loading"
              class="h-6 object-contain bg-buttonColor rounded-md cursor-pointer hover:shadow-lg"
              src="@/assets/icons/cross-white.png"
              @click="close()"
            />
          </div>
          <form @submit.prevent="createProvider">
            <div class="p-6">
              <div class="grid grid-cols-2 gap-5">
                <div>
                  <label class="text-sm">Name*</label>
                  <input
                    v-model="data.Name"
                    required
                    type="text"
                    class="p-1 mt-1 block w-full bg-gray-200 focus:bg-white"
                  />
                  <span
                    v-if="modelError && modelError['create.' + 'Name']"
                    class="text-red-500 text-sm block w-full"
                  >
                    {{ modelError["create." + "Name"][0] }}
                  </span>
                </div>
                <div>
                  <label class="text-sm">Request Uri*</label>
                  <input
                    v-model="data.RequestUri"
                    required
                    type="text"
                    class="p-1 mt-1 block w-full bg-gray-200 focus:bg-white"
                  />
                  <span
                    v-if="modelError && modelError['create.' + 'RequestUri']"
                    class="text-red-500 text-sm block w-full"
                  >
                    {{ modelError["create." + "RequestUri"][0] }}
                  </span>
                </div>
              </div>
              <div class="grid grid-cols-2 gap-5 mt-4">
                <div>
                  <label class="text-sm">Gateway Host*</label>
                  <input
                    v-model="data.GatewayHost"
                    required
                    type="text"
                    class="p-1 mt-1 block w-full bg-gray-200 focus:bg-white"
                  />
                  <span
                    v-if="modelError && modelError['create.' + 'GatewayHost']"
                    class="text-red-500 text-sm block w-full"
                  >
                    {{ modelError["create." + "GatewayHost"][0] }}
                  </span>
                </div>
                <div>
                  <label class="text-sm">Chid*</label>
                  <input
                    v-model="data.Chid"
                    required
                    type="text"
                    class="p-1 mt-1 block w-full bg-gray-200 focus:bg-white"
                  />
                  <span
                    v-if="modelError && modelError['create.' + 'Chid']"
                    class="text-red-500 text-sm block w-full"
                  >
                    {{ modelError["create." + "Chid"][0] }}
                  </span>
                </div>
              </div>
              <div class="grid grid-cols-2 gap-5 mt-4">
                <div>
                  <label class="text-sm">Lat*</label>
                  <input
                    v-model="data.Lat"
                    required
                    type="text"
                    class="p-1 mt-1 block w-full bg-gray-200 focus:bg-white"
                  />
                  <span
                    v-if="modelError && modelError['create.' + 'Lat']"
                    class="text-red-500 text-sm block w-full"
                  >
                    {{ modelError["create." + "Lat"][0] }}
                  </span>
                </div>
                <div>
                  <label class="text-sm">Lng*</label>
                  <input
                    v-model="data.Lng"
                    required
                    type="text"
                    class="p-1 mt-1 block w-full bg-gray-200 focus:bg-white"
                  />
                  <span
                    v-if="modelError && modelError['create.' + 'Lng']"
                    class="text-red-500 text-sm block w-full"
                  >
                    {{ modelError["create." + "Lng"][0] }}
                  </span>
                </div>
              </div>
              <div class="flex gap-2 text-sm my-3 items-center">
                <p class="font-semibold">Provider Operations</p>
              </div>
              <div
                v-for="(operation, index) in data.ProviderOperations"
                :key="index"
              >
                <div class="grid grid-cols-4 gap-5 mt-4 items-center">
                  <div>
                    <label class="text-sm">Operation*</label>
                    <select
                      required
                      v-model="data.ProviderOperations[index].Operation"
                      class="p-1 mt-1 block w-full bg-gray-200 focus:bg-white"
                    >
                      <option
                        v-for="(operation, index) in operations"
                        :key="index"
                      >
                        {{ operation }}
                      </option>
                    </select>
                    <span
                      v-if="modelError && modelError['create.' + 'Operation']"
                      class="text-red-500 text-sm block w-full"
                    >
                      {{ modelError["create." + "Operation"][index] }}
                    </span>
                  </div>
                  <div>
                    <label class="text-sm">Action*</label>
                    <select
                      required
                      v-model="data.ProviderOperations[index].Action"
                      class="p-1 mt-1 block w-full bg-gray-200 focus:bg-white"
                    >
                      <option v-for="(action, index) in actions" :key="index">
                        {{ action }}
                      </option>
                    </select>
                    <span
                      v-if="modelError && modelError['create.' + 'Action']"
                      class="text-red-500 text-sm block w-full"
                    >
                      {{ modelError["create." + "Action"][index] }}
                    </span>
                  </div>
                  <div>
                    <label class="text-sm">Route*</label>
                    <input
                      v-model="data.ProviderOperations[index].Route"
                      required
                      type="text"
                      class="p-1 mt-1 block w-full bg-gray-200 focus:bg-white"
                    />
                    <span
                      v-if="modelError && modelError['create.' + 'Route']"
                      class="text-red-500 text-sm block w-full"
                    >
                      {{ modelError["create." + "Route"][index] }}
                    </span>
                  </div>
                  <div v-show="index != 0">
                    <label class="text-sm invisible">s</label>
                    <button
                      type="button"
                      @click="removeProviderOperations(index)"
                      class="bg-gray-500 block text-white text-xs p-1"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
              <div class="flex gap-2 text-sm my-3 items-center">
                <p
                  class="text-blue-500 hover:underline cursor-pointer"
                  @click="addProviderOperations"
                >
                  Add another provider operation
                </p>
              </div>

              <div class="flex justify-center mt-10">
                <button type="submit" class="bg-buttonColor rounded-md text-white p-2 w-1/2">
                  Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      >
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
var qs = require("qs");
import Loader from "../loader/loader.vue";
export default {
  name: "CreateServiceProvider",
  components: { Loader },
  props: ["showcreateProvider"],
  data() {
    return {
      loading: false,
      operations: [],
      actions: ["POST", "GET", "PUT", "DELETE"],
      data: {
        Name: null,
        RequestUri: null,
        GatewayHost: null,
        Chid: null,
        Lat: null,
        Lng: null,
        ProviderOperations: [
          {
            Operation: null,
            Action: null,
            Route: null,
          },
        ],
      },
      modelError: {},
    };
  },
  computed: {
    ...mapGetters(["getToken"]),
  },
  created() {
    this.getOperations();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    addProviderOperations() {
      this.data.ProviderOperations.push({
        Operation: null,
        Action: null,
        Route: null,
      });
    },
    removeProviderOperations(index) {
      this.data.ProviderOperations.splice(index, 1);
    },
    getOperations() {
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .get(
          `${this.$route.params.organization}/provider/operation/request`,
          options
        )
        .then((response) => {
          this.operations = this.objectToArray(response.data);
        });
    },
    objectToArray(obj) {
      return Object.keys(obj).map((key) => {
        return obj[key];
      });
    },
    createProvider() {
      this.loading = true;
      this.modelError = {};
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .post(
          `${this.$route.params.organization}/sms/provider/create`,
          qs.stringify(this.data),
          options
        )
        .then(
          () => {
            this.loading = false;
            this.$emit("updateProviderTable");
          },
          (error) => {
            this.loading = false;
            if (
              error.response["data"] &&
              error.response["data"]["ModelState"]
            ) {
              this.modelError = error.response["data"]["ModelState"];
            }
          }
        );
    },
  },
};
</script>

<style scoped>
.center-h-v {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
