var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-primaryDark"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.showAssignedShortcodes)?_c('div',{staticClass:"bg-black fixed bg-opacity-70 inset-0 z-50 h-screen w-full"},[_c('div',{staticClass:"fixed center-h-v bg-white sm:w-1/2 w-full h-3/4 shadow-2xl rounded-md"},[(_vm.loading)?_c('div',{staticClass:"absolute inset-0 bg-black bg-opacity-50"},[_c('loader',{staticClass:"absolute center-h-v"})],1):_vm._e(),_c('div',{staticClass:"flex justify-between items-center border-b-1.5 p-3"},[_c('p',{},[_vm._v("Assign shortcodes to user")]),(!_vm.loading)?_c('img',{staticClass:"h-5 object-contain bg-buttonColor rounded-md cursor-pointer hover:shadow-lg",attrs:{"src":require("@/assets/icons/cross-white.png")},on:{"click":function($event){return _vm.close()}}}):_vm._e()]),_c('div',{staticClass:"grid grid-cols-2 divide-x-1.5 h-3/4"},[_c('div',{staticClass:"p-4"},[_c('div',{staticClass:"border-b-1.5 pb-3 text-sm"},[_c('p',[_vm._v("Name: "+_vm._s(_vm.currentRowData.FullName))]),_c('p',[_vm._v("Email: "+_vm._s(_vm.currentRowData.Email))]),_c('p',[_vm._v("Organization: "+_vm._s(_vm.currentRowData.Organization))]),_c('p',[_vm._v("Role: "+_vm._s(_vm.currentRowData.Role))])]),_c('div',[_c('p',{staticClass:"font-medium py-4"},[_vm._v("Assigned Shortcodes")]),_vm._l((_vm.currentRowData.Shortcodes),function(shortcode){return _c('div',{key:shortcode.ShortcodeId},[_c('div',{staticClass:"flex justify-between my-2 bg-gray-100"},[_c('p',[_vm._v(" "+_vm._s(shortcode.ShortcodeName)+" - "+_vm._s(shortcode.Shortcode)+" ")]),_c('button',{staticClass:"bg-primaryDark p-1 cursor-pointer flex gap-1 text-sm items-center text-white",on:{"click":function($event){return _vm.unassignShortcode(
                        _vm.currentRowData.Id,
                        shortcode.ShortcodeId
                      )}}},[_c('img',{staticClass:"h-3",attrs:{"src":require("@/assets/icons/trash.png")}}),_vm._v(" Remove ")])])])})],2)]),_c('div',{staticClass:"p-4"},[_c('p',{staticClass:"font-medium pb-3"},[_vm._v("Shortcodes")]),_vm._l((_vm.availableShortcodes),function(shortcode){return _c('div',{key:shortcode.Id},[_c('div',{staticClass:"flex gap-0 justify-between my-2 bg-gray-100"},[_c('p',[_vm._v(" "+_vm._s(shortcode.ShortcodeName)+" - "+_vm._s(shortcode.Shortcode)+" ")]),(!_vm.isShortcodeAssigned(shortcode.Shortcode))?_c('button',{staticClass:"bg-primaryDark p-1 cursor-pointer flex gap-1 text-sm items-center text-white hover:shadow-sm",on:{"click":function($event){return _vm.assignShortcode(
                      _vm.currentRowData.Id,
                      shortcode.Id,
                      shortcode.Shortcode,
                      shortcode.ShortcodeName
                    )}}},[_c('img',{staticClass:"h-3",attrs:{"src":require("@/assets/icons/add.png")}}),_vm._v(" Assign ")]):_vm._e()])])})],2)])])]):_vm._e(),_vm._v(" > ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }