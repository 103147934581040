<template>
  <div class="relative">
    <div v-if="loading" class="absolute inset-0 bg-black bg-opacity-10 z-40">
      <loader class="absolute center-h-v" />
    </div>

    <ve-table class="p-3" :columns="columns" :table-data="tableData" :row-style-option="rowStyleOption" :border-x="true"
      :border-y="true" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import loader from "../loader/loader.vue";
var qs = require("qs");
import axios from "axios";
export default {
  name: "SmsGatewayTable",
  components: { loader },
  props: ["hasGatewayListChanged"],
  data() {
    return {
      rowStyleOption: {
        stripe: true,
      },
      searchInput: '',
      columns: [
        {
          field: "",
          key: "numbering",
          title: "#",
          width: 50,
          align: "center",
          renderBodyCell: ({ rowIndex }) => {
            return ++rowIndex;
          },
        },
        {
          field: "SMSProvider",
          key: "a",
          title: "SMS Gateway Provider",
          align: "left",
          renderBodyCell: ({ row }) => {
            return (
              <p
                on-click={() => this.editRow(row)}
                class="hover:underline text-blue-500 cursor-pointer"
              >
                {row.SMSProvider}
              </p>
            );
          },
        },
        {
          field: "",
          key: "cx",
          title: "Edit",
          align: "center",
          renderBodyCell: ({ row }) => {
            return (
              <p
                on-click={() => this.editRow(row)}
                class=" text-blue-500 cursor-pointer transform rotate-90 font-bold"
              >
                <span class="rotate-90">&#9998;</span>
              </p>
            );
          },
        },
        {
          field: "SenderId",
          key: "b",
          title: "Sender ID",
          align: "left",
        },
        {
          field: "Default",
          key: "c",
          title: "Default Gateway",
          align: "left",
          renderBodyCell: ({ row, column }) => {
            const value = row[column.field];
            return value ? (
              <input
                type="checkbox"
                id="Default"
                name="Default"
                disabled
                checked
              />
            ) : (
              <input
                on-click={() => this.makeDefault(row)}
                type="checkbox"
                id="Default"
                name="Default"
              />
            );
          },
        },
      ],
      tableData: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getToken"]),
  },
  created() {
    this.getGateways();
  },
  watch: {
    hasGatewayListChanged(newValue) {
      if (newValue) {
        this.getGateways();
      }
    },
  },
  methods: {
    editRow(row) {
      this.$emit("editGateway", row);
    },
    makeDefault(row) {
      var data = {
        Id: row.Id,
      };
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .put(
          `${this.$route.params.organization}/gateway/default/update`,
          qs.stringify(data),
          options
        )
        .then(() => {
          this.$emit("updateGatewayTable");
          this.getGateways();
        });
    },
    getGateways() {
      this.tableData = [];
      this.loading = true;
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .get(
          `${this.$route.params.organization}/gateway/query?sortString=Newest&order=ASC&pagingparametermodel.pageNumber=1&pagingparametermodel._pageSize=200&pagingparametermodel.pageSize=200`,
          options
        )
        .then(
          (response) => {
            this.tableData = response.data.gateways;
            this.loading = false;
          },
          () => {
            this.loading = false;
            //this.error = true;
          }
        );
    },
  },
};
</script>