<template>
  <div class="flex flex-col flex-nowrap">
    <top-nav-bar />
    <div class="flex flex-row flex-nowrap">
      <side-bar @selectedMenu="changeMenu" />
      <div class="w-full bg-backgroundGray sm:mb-0 mb-16">
        <!-- content area -->
        <dashboard v-if="selectedMenu == 'Dashboard'" :key="dbKey" />
        <setups v-if="selectedMenu == 'Setups'" :key="stKey" />
        <transactions v-if="selectedMenu == 'Transactions'" :key="trKey" />
        <IAndMTransactions v-if="selectedMenu == 'I&M Transactions'" :key="trKey" />
        <organizations v-if="selectedMenu == 'Organizations'" :key="oKey" />
        <users v-if="selectedMenu == 'Users'" :key="usKey" />
        <short-codes v-if="selectedMenu == 'Shortcodes'" :key="scKey" />
      </div>
    </div>
  </div>
</template>

<script>
import TopNavBar from "../components/dashboardLayout/topNavBar.vue";
import SideBar from "../components/dashboardLayout/sideBar.vue";
import Dashboard from "./dashboard.vue";
import Setups from "./setups.vue";
import Users from "./users.vue";
import Organizations from "./organizations.vue";
import ShortCodes from "./shortCodes.vue";
import Transactions from "./transactions.vue";
import IAndMTransactions from "./I&MTransactions.vue";
import { mapGetters } from "vuex";
export default {
  name: "DashboardLayout",
  components: {
    TopNavBar,
    SideBar,
    Dashboard,
    Setups,
    Users,
    ShortCodes,
    Transactions,
    IAndMTransactions,
    Organizations,
  },
  data() {
    return {
      selectedMenu: null,
      dbKey: 1,
      trKey: 2,
      stKey: 3,
      usKey: 4,
      scKey: 5,
      oKey: 6,
    };
  },
  computed: {
    ...mapGetters(["isPasswordChanged", "getOrganization", "isAuthenticated"]),
  },
  // created() {
  //   if (this.isAuthenticated) {
  //     if (this.getOrganization !== this.$route.params.organization) {
  //       console.log(this.getOrganization, this.$route.params.organization);
  //       this.$router.replace({ name: "NotFound" });
  //     }
  //   }
  // },
  mounted() {
    if (this.isPasswordChanged == "True") {
      this.$router.replace({ name: "dashboard" });
    } else {
      this.$router.replace({ name: "changePassword" });
    }
  },
  methods: {
    changeMenu(name) {
      this.selectedMenu = name;
      switch (this.selectedMenu) {
        case "Dashboard":
          this.dbKey++;
          break;
        case "Users":
          this.stKey++;
          break;
        case "Transactions":
          this.trKey++;
          break;
        case "Setups":
          this.usKey++;
          break;
        case "Short Codes":
          this.usKey++;
          break;
        case "Organizations":
          this.oKey++;
          break;
      }
    },
  },
};
</script>