<template>
  <div class="relative" style="min-height: 50px">
    <div v-if="loading" class="absolute inset-0 bg-black bg-opacity-10 rounded-b z-50">
      <loader class="absolute center-h-v" />
    </div>
    <div v-if="organizationData" class="grid grid-cols-1 sm:grid-cols-2 divide-x-0.5">
      <div class="p-5 flex flex-col gap-5">
        <div class="flex flex-row justify-between gap-5">
          <p class="text-sm">Send Mpesa Acknowledgement SMS</p>
          <label class="switch">
            <input @click="update('sms')" type="checkbox" v-model="organizationData.SendMPesaAcknowledgement"
              :value="organizationData.SendMPesaAcknowledgement" />
            <div class="slider round"></div>
          </label>
        </div>
        <div>
          <div class="flex flex-row justify-between gap-5 relative mb-3">
            <p class="text-sm">Sync Mpesa Transactions</p>
            <label class="switch">
              <input @click="update('erp')" type="checkbox" v-model="organizationData.SyncMPesaTransactions"
                :value="organizationData.SyncMPesaTransactions" />
              <div class="slider round"></div>
            </label>
          </div>
          <div v-if="!this.organizationData.MPesaTransactionAPIKey.trim().length">
            <p class="text-sm py-5 text-textColorGray">
              In order to sync M-Pesa payment transactions to the ERP, an API
              key is required. Click the button below to generate the key. Once
              the key is generated, copy it and paste it into the ERP setup
              page.
            </p>
          </div>
          <div>
            <!--  v-if="this.organizationData.MPesaTransactionAPIKey.trim().length && this.organizationData.SyncMPesaTransactions" -->
            <!-- <p class="text-sm py-5 text-textColorGray">
              You have already generated an API key. To generate a new key,
              click the button below. The old API key will be revoked.
            </p> -->
            <div>
              <div class="flex gap-4 flex-wrap items-center pb-2 text-xs">
                <p class="text-textColorGray">Mpesa Transaction API Key</p>
                <p @click="
                  copyMPesaTransactionAPIKey(
                    organizationData.MPesaTransactionAPIKey
                  )
                  " class="cursor-pointer text-buttonColor font-bold">
                  Copy Key
                </p>
              </div>
              <p v-if="MpesaTransactionApiTextCopied" class="bg-black p-2 text-white text-xs d-flex flex-wrap"
                style="width:100%;">
                The Mpesa API key has been copied
              </p>

              <div class="flex gap-2">
                <div class="grow-1">
                  <p
                    class="text-xs bg-buttonColor text-center bg-opacity-30 text-textColorGray p-1 flex justify-between gap-2">
                    <span v-if="showMpesaTransactionApiKey">{{
                      organizationData.MPesaTransactionAPIKey
                    }}</span>
                    <span v-else>{{ hideMpesaTransactionApiKey() }}</span>
                    <span class="toggle-icon" @click="toggleMpesaTransactionApiKeyVisibility">
                      <svg class="cursor-pointer" v-if="showMpesaTransactionApiKey" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24" width="15" height="15">
                        <g id="_01_align_center" data-name="01 align center">
                          <path
                            d="M23.821,11.181v0a15.736,15.736,0,0,0-4.145-5.44l3.032-3.032L21.293,1.293,18,4.583A11.783,11.783,0,0,0,12,3C4.5,3,1.057,9.261.179,11.181a1.969,1.969,0,0,0,0,1.64,15.736,15.736,0,0,0,4.145,5.44L1.293,21.293l1.414,1.414L6,19.417A11.783,11.783,0,0,0,12,21c7.5,0,10.943-6.261,11.821-8.181A1.968,1.968,0,0,0,23.821,11.181ZM2,12.011C2.75,10.366,5.693,5,12,5a9.847,9.847,0,0,1,4.518,1.068L14.753,7.833a4.992,4.992,0,0,0-6.92,6.92L5.754,16.832A13.647,13.647,0,0,1,2,12.011ZM15,12a3,3,0,0,1-3,3,2.951,2.951,0,0,1-1.285-.3L14.7,10.715A2.951,2.951,0,0,1,15,12ZM9,12a3,3,0,0,1,3-3,2.951,2.951,0,0,1,1.285.3L9.3,13.285A2.951,2.951,0,0,1,9,12Zm3,7a9.847,9.847,0,0,1-4.518-1.068l1.765-1.765a4.992,4.992,0,0,0,6.92-6.92l2.078-2.078A13.584,13.584,0,0,1,22,12C21.236,13.657,18.292,19,12,19Z" />
                        </g>
                      </svg>

                      <svg class="cursor-pointer" v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15"
                        height="15">
                        <g id="_01_align_center" data-name="01 align center">
                          <path
                            d="M23.821,11.181v0C22.943,9.261,19.5,3,12,3S1.057,9.261.179,11.181a1.969,1.969,0,0,0,0,1.64C1.057,14.739,4.5,21,12,21s10.943-6.261,11.821-8.181A1.968,1.968,0,0,0,23.821,11.181ZM12,19c-6.307,0-9.25-5.366-10-6.989C2.75,10.366,5.693,5,12,5c6.292,0,9.236,5.343,10,7C21.236,13.657,18.292,19,12,19Z" />
                          <path d="M12,7a5,5,0,1,0,5,5A5.006,5.006,0,0,0,12,7Zm0,8a3,3,0,1,1,3-3A3,3,0,0,1,12,15Z" />
                        </g>
                      </svg>
                    </span>
                  </p>
                </div>
                <div class="grow-1">
                  <!-- v-if="this.organizationData.SyncMPesaTransactions" @click="generateMpesaApiKey" -->
                  <button class="bg-buttonColor text-sm text-white rounded-md p-1" @click="generateMpesaApiKey">
                    {{
                      organizationData.MPesaTransactionAPIKey != null
                      ? "Regenerate"
                      : "Generate"
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Mpesa Transaction URL -->
          <div class="mt-3">
            <div class="flex gap-4 flex-wrap items-start pb-2 text-xs">
              <p class="text-textColorGray">Mpesa Transaction URL </p>
              <p @click="
              copyMPesaTransactionUrl(
                organizationData.MPesaTransactionUrl
              )
                " class="cursor-pointer text-buttonColor font-bold">
                Copy Url
              </p>
            </div>
            <p v-if="MpesaTransactionTextCopied" class="bg-black p-2 text-white text-xs d-flex flex-wrap"
              style="width:100%;">
              The Mpesa Transaction URL has been copied
            </p>

            <div class="flex gap-2">
              <div class="grow-1 rounded-md" style="width: 100%;">
                <p class="text-xs radi bg-buttonColor text-left bg-opacity-30 text-textColorGray p-1 flex justify-between gap-2"
                  style="width: 100%;">

                  {{ organizationData.MPesaTransactionUrl }}


                </p>
              </div>

            </div>
          </div>



          <div class="mt-3">
            <div class="flex gap-4 flex-wrap items-left pb-2 text-xs">
              <p class="text-textColorGray">Update MPesa Transaction Url </p>
              <p @click="
                copyUpdateMPesaTransactionUrl(
                  organizationData.UpdateMPesaTransactionUrl
                )
                " class="cursor-pointer text-buttonColor font-bold">
                Copy Url
              </p>
            </div>
            <p v-if="MpesaUpdateTransactionTextCopied" class="bg-black p-2 text-white text-xs d-flex flex-wrap"
              style="width:100%;">
              The Mpesa Transaction Update URL has been copied
            </p>

            <div class="flex gap-2">
              <div class="grow-1 rounded-md" style="width: 100%;">
                <p class="text-xs radi bg-buttonColor text-left bg-opacity-30 text-textColorGray p-1 flex justify-between gap-2"
                  style="width: 100%;">
                  {{ organizationData.UpdateMPesaTransactionUrl }}
                </p>
              </div>

            </div>
          </div>
          <!-- End Mpesa Transaction URL -->
        </div>
      </div>
      <div class="p-5 flex flex-col gap-5">
        <div class="flex flex-row justify-between gap-5">
          <p class="text-sm">Send I&M Acknowledgement</p>
          <label class="switch">
            <input @click="update('sms')" type="checkbox" v-model="organizationData.SendIMAcknowledgement"
              :value="organizationData.SendIMAcknowledgement" />
            <div class="slider round"></div>
          </label>
        </div>
        <div>
          <div class="flex flex-row justify-between gap-5 relative mb-3">
            <p class="text-sm">Sync I&M Transaction</p>
            <label class="switch">
              <input @click="update('erp')" type="checkbox" v-model="organizationData.SyncIMTransaction"
                :value="organizationData.SyncIMTransaction" />
              <div class="slider round"></div>
            </label>
          </div>
          <div class="flex flex-row justify-between mt-2 gap-5 relative mb-3">
            <p class="text-sm">Enable I&M Connect</p>
            <label class="switch">
              <input @click="update('erp')" type="checkbox" v-model="organizationData.EnableIMConnect"
                :value="organizationData.EnableIMConnect" />
              <div class="slider round"></div>
            </label>
          </div>
          <div v-if="!this.organizationData.IMTransactionAPIKey.trim().length">
            <p class="text-sm py-5 text-textColorGray">
              In order to sync M-Pesa payment transactions to the ERP, an API
              key is required. Click the button below to generate the key. Once
              the key is generated, copy it and paste it into the ERP setup
              page.
            </p>
          </div>
          <div>
            <!-- v-if="this.organizationData.IMTransactionAPIKey.trim().length && this.organizationData.SyncIMTransaction" -->
            <!-- <p class="text-sm py-5 text-textColorGray">
              You have already generated an API key. To generate a new key,
              click the button below. The old API key will be revoked.
            < radi/p> -->
            <div style="width: 100%;">
              <div class="flex gap-4 flex-wrap items-center pb-2 text-xs">
                <p class="text-textColorGray">I&M Transaction API Key</p>
                <p @click="
                  copyIMTransactionAPIKey(
                    organizationData.IMTransactionAPIKey
                  )
                  " class="cursor-pointer text-buttonColor font-bold">
                  Copy Key
                </p>
              </div>
              <p v-if="IAndMTextCopied" class="bg-black p-2 text-white text-xs d-flex flex-wrap" style="width:100%;">
                The I&M API key has been copied
              </p>
              <div class="flex gap-2">
                <div class="grow-1">
                  <p
                    class="text-xs bg-buttonColor text-center bg-opacity-30 text-textColorGray p-1 flex justify-between align-middle gap-2">
                    <span v-if="showIMTransactionApiKey">{{
                      organizationData.IMTransactionAPIKey
                    }}</span>
                    <span v-else>{{ hideIMKey() }}</span>
                    <span class="toggle-icon" @click="toggleIMTransactionApiKeyVisibility">
                      <svg class="cursor-pointer" v-if="showIMTransactionApiKey" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24" width="15" height="15">
                        <g id="_01_align_center" data-name="01 align center">
                          <path
                            d="M23.821,11.181v0a15.736,15.736,0,0,0-4.145-5.44l3.032-3.032L21.293,1.293,18,4.583A11.783,11.783,0,0,0,12,3C4.5,3,1.057,9.261.179,11.181a1.969,1.969,0,0,0,0,1.64,15.736,15.736,0,0,0,4.145,5.44L1.293,21.293l1.414,1.414L6,19.417A11.783,11.783,0,0,0,12,21c7.5,0,10.943-6.261,11.821-8.181A1.968,1.968,0,0,0,23.821,11.181ZM2,12.011C2.75,10.366,5.693,5,12,5a9.847,9.847,0,0,1,4.518,1.068L14.753,7.833a4.992,4.992,0,0,0-6.92,6.92L5.754,16.832A13.647,13.647,0,0,1,2,12.011ZM15,12a3,3,0,0,1-3,3,2.951,2.951,0,0,1-1.285-.3L14.7,10.715A2.951,2.951,0,0,1,15,12ZM9,12a3,3,0,0,1,3-3,2.951,2.951,0,0,1,1.285.3L9.3,13.285A2.951,2.951,0,0,1,9,12Zm3,7a9.847,9.847,0,0,1-4.518-1.068l1.765-1.765a4.992,4.992,0,0,0,6.92-6.92l2.078-2.078A13.584,13.584,0,0,1,22,12C21.236,13.657,18.292,19,12,19Z" />
                        </g>
                      </svg>

                      <svg class="cursor-pointer" v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15"
                        height="15">
                        <g id="_01_align_center" data-name="01 align center">
                          <path
                            d="M23.821,11.181v0C22.943,9.261,19.5,3,12,3S1.057,9.261.179,11.181a1.969,1.969,0,0,0,0,1.64C1.057,14.739,4.5,21,12,21s10.943-6.261,11.821-8.181A1.968,1.968,0,0,0,23.821,11.181ZM12,19c-6.307,0-9.25-5.366-10-6.989C2.75,10.366,5.693,5,12,5c6.292,0,9.236,5.343,10,7C21.236,13.657,18.292,19,12,19Z" />
                          <path d="M12,7a5,5,0,1,0,5,5A5.006,5.006,0,0,0,12,7Zm0,8a3,3,0,1,1,3-3A3,3,0,0,1,12,15Z" />
                        </g>
                      </svg>
                    </span>
                  </p>
                </div>
                <div class="grow-1">
                  <button class="bg-buttonColor text-sm text-white rounded-md p-1" @click="generateIMTransactionKey">
                    {{
                      organizationData.IMTransactionAPIKey != null
                      ? "Regenerate"
                      : "Generate"
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div>
              <div class="flex gap-4 flex-wrap items-center pb-2 text-xs mt-3">
                <p class="text-textColorGray">I&M Callback API Key</p>
                <p @click="
                copyIAndMCallbackKey(
                  organizationData.IMCallbackAPIKey
                )
                  " class="cursor-pointer text-buttonColor font-bold">
                  Copy Key
                </p>
              </div>
              <p v-if="IAndMCallbackKeyTextCopied" class="bg-black p-2 text-white text-xs d-flex flex-wrap"
                style="width:100%;">
                The I&M Callback key has been copied
              </p>
              <div class="flex gap-2">
                <div class="grow-1">
                  <div
                    class="text-xs bg-buttonColor text-center bg-opacity-30 text-textColorGray p-1 flex justify-between align-middle gap-2">
                    <span v-if="showIAndMCallbackKey" class="inline-block overflow-x-auto custom-scrollbar">{{
                      organizationData.IMCallbackAPIKey
                    }}</span>
                    <span v-else>{{ hideIAndMCallbackKey() }}</span>
                    <span class="toggle-icon" @click="toggleIAndMCallbackKeyVisibility">
                      <svg class="cursor-pointer" v-if="showIAndMCallbackKey" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24" width="15" height="15">
                        <g id="_01_align_center" data-name="01 align center">
                          <path
                            d="M23.821,11.181v0a15.736,15.736,0,0,0-4.145-5.44l3.032-3.032L21.293,1.293,18,4.583A11.783,11.783,0,0,0,12,3C4.5,3,1.057,9.261.179,11.181a1.969,1.969,0,0,0,0,1.64,15.736,15.736,0,0,0,4.145,5.44L1.293,21.293l1.414,1.414L6,19.417A11.783,11.783,0,0,0,12,21c7.5,0,10.943-6.261,11.821-8.181A1.968,1.968,0,0,0,23.821,11.181ZM2,12.011C2.75,10.366,5.693,5,12,5a9.847,9.847,0,0,1,4.518,1.068L14.753,7.833a4.992,4.992,0,0,0-6.92,6.92L5.754,16.832A13.647,13.647,0,0,1,2,12.011ZM15,12a3,3,0,0,1-3,3,2.951,2.951,0,0,1-1.285-.3L14.7,10.715A2.951,2.951,0,0,1,15,12ZM9,12a3,3,0,0,1,3-3,2.951,2.951,0,0,1,1.285.3L9.3,13.285A2.951,2.951,0,0,1,9,12Zm3,7a9.847,9.847,0,0,1-4.518-1.068l1.765-1.765a4.992,4.992,0,0,0,6.92-6.92l2.078-2.078A13.584,13.584,0,0,1,22,12C21.236,13.657,18.292,19,12,19Z" />
                        </g>
                      </svg>

                      <svg class="cursor-pointer" v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15"
                        height="15">
                        <g id="_01_align_center" data-name="01 align center">
                          <path
                            d="M23.821,11.181v0C22.943,9.261,19.5,3,12,3S1.057,9.261.179,11.181a1.969,1.969,0,0,0,0,1.64C1.057,14.739,4.5,21,12,21s10.943-6.261,11.821-8.181A1.968,1.968,0,0,0,23.821,11.181ZM12,19c-6.307,0-9.25-5.366-10-6.989C2.75,10.366,5.693,5,12,5c6.292,0,9.236,5.343,10,7C21.236,13.657,18.292,19,12,19Z" />
                          <path d="M12,7a5,5,0,1,0,5,5A5.006,5.006,0,0,0,12,7Zm0,8a3,3,0,1,1,3-3A3,3,0,0,1,12,15Z" />
                        </g>
                      </svg>
                    </span>
                  </div>
                </div>
                <div class="grow-1">
                  <button class="bg-buttonColor text-sm text-white rounded-md p-1" @click="generateIAndMCallbackKey">
                    {{
                      organizationData.IMCallbackAPIKey != null
                      ? "Regenerate"
                      : "Generate"
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>


          <!-- I&M Transaction URL -->
          <div class="mt-3">
            <div class="flex gap-4 flex-wrap items-start pb-2 text-xs">
              <p class="text-textColorGray">I&M Callback URL </p>
              <p @click="
              copyMPesaCallbackUrl(
                organizationData.IMCallbackUrl
              )
                " class="cursor-pointer text-buttonColor font-bold">
                Copy Url
              </p>
            </div>
            <p v-if="MpesaCallbackUrlTextCopied" class="bg-black p-2 text-white text-xs d-flex flex-wrap"
              style="width:100%;">
              The I&M Callback URL has been copied
            </p>

            <div class="flex gap-2">
              <div class="grow-1 rounded-md" style="width: 100%;">
                <p class="text-xs radi bg-buttonColor text-left bg-opacity-30 text-textColorGray p-1 flex justify-between gap-2"
                  style="width: 100%;">

                  {{ organizationData.IMCallbackUrl }}


                </p>
              </div>

            </div>
          </div>


          <div class="mt-3">
            <div class="flex gap-4 flex-wrap items-start pb-2 text-xs">
              <p class="text-textColorGray">I&M Transaction URL </p>
              <p @click="
                copyIMTransactionUrl(
                  organizationData.IMTransactionUrl
                )
                " class="cursor-pointer text-buttonColor font-bold ">
                Copy Url
              </p>
            </div>
            <p v-if="IMTransactionTextCopied" class="bg-black p-2 text-white text-xs d-flex flex-wrap"
              style="width:100%;">
              The I&M Transaction URL has been copied
            </p>

            <div class="flex gap-2">
              <div class="grow-1 rounded-md" style="width: 100%;">
                <p class="text-xs radi bg-buttonColor text-left bg-opacity-30 text-textColorGray p-1 flex justify-between gap-2"
                  style="width: 100%;">

                  {{ organizationData.IMTransactionUrl }}


                </p>
              </div>

            </div>
          </div>


          <div class="mt-3">
            <div class="flex gap-4 flex-wrap items-left pb-2 text-xs">
              <p class="text-textColorGray">Update I&M Transaction Url </p>
              <p @click="
                copyIMUpdateTransactionUrl(
                  organizationData.UpdateIMTransactionUrl
                )
                " class="cursor-pointer text-buttonColor font-bold">
                Copy Url
              </p>
            </div>
            <p v-if="IMUpdateTransactionTextCopied" class="bg-black p-2 text-white text-xs d-flex flex-wrap"
              style="width:100%;">
              The I&M Update Transaction URL has been copied
            </p>

            <div class="flex gap-2">
              <div class="grow-1 rounded-md" style="width: 100%;">
                <p class="text-xs  bg-buttonColor text-left bg-opacity-30 text-textColorGray p-1 flex justify-between gap-2"
                  style="width: 100%;">
                  {{ organizationData.UpdateIMTransactionUrl }}
                </p>
              </div>

            </div>
          </div>
          <!-- End I&M Transaction URL -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Loader from "../loader/loader.vue";
var qs = require("qs");
export default {
  name: "OrganizationSettings",
  components: {
    Loader,
  },
  data: function () {
    return {
      loading: false,
      showIMTransactionApiKey: false,
      showMpesaTransactionApiKey: false,
      showIAndMCallbackKey: false,
      mpesaTextCopied: false,
      IMTransactionTextCopied: false,
      IMUpdateTransactionTextCopied: false,
      MpesaUpdateTransactionTextCopied: false,
      MpesaTransactionTextCopied: false,
      MpesaTransactionApiTextCopied: false,
      IAndMCallbackKeyTextCopied: false,
      MpesaCallbackUrlTextCopied: false,
      IAndMTextCopied: false,
      SyncToERPAPiKey: false,
      organizationData: null,
    };
  },
  computed: {
    ...mapGetters(["getToken"]),
  },
  created() {
    this.getThisOrganization();
  },
  methods: {
    testHander() {
      console.log('test handle')
    },
    // I&M
    toggleIMTransactionApiKeyVisibility() {
      this.showIMTransactionApiKey = !this.showIMTransactionApiKey;
    },
    hideIMKey() {
      // Replace this logic with any desired masking or hiding behavior
      return "*".repeat(this.organizationData.IMTransactionAPIKey.length);
    },
    hideIAndMCallbackKey() {
      return "*".repeat(this.organizationData.IMTransactionAPIKey.length);

    },
    toggleIAndMCallbackKeyVisibility() {
      this.showIAndMCallbackKey = !this.showIAndMCallbackKey;
    },
    // END I&M

    // Mpesa
    toggleMpesaTransactionApiKeyVisibility() {
      // alert(1)
      this.showMpesaTransactionApiKey = !this.showMpesaTransactionApiKey;
    },
    hideMpesaTransactionApiKey() {
      // Replace this logic with any desired masking or hiding behavior
      return "*".repeat(this.organizationData.MPesaTransactionAPIKey.length);
    },

    // End Mpesa
    getThisOrganization() {
      this.loading = true;
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .get(
          `${this.$route.params.organization}/organization/request`,
          options,
          this.organizationData
        )
        .then((res) => {
          this.loading = false;
          this.organizationData = res.data;
          if (this.organizationData.APIKey) {
            this.SyncToERPAPiKey = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // https://api.lipalink.com/glacierproductsltd/organization/mpesa/transaction/key/v1/generate
    generateMpesaApiKey() {
      this.loading = true;
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .post(
          `${this.$route.params.organization}/organization/mpesa/transaction/key/v1/generate`,
          qs.stringify(this.data),
          options
        )
        .then(
          (res) => {
            this.loading = false;
            let data = {
              msg: "API key has been generated",
              color: "bg-green-500",
            };
            this.$emit("status", data);
            this.getThisOrganization();
            this.organizationData.APIKey = res.data.APIKey;
            this.SyncToERPAPiKey = true;
          },
          () => {
            this.loading = false;
            let data = {
              msg: "An error occured please try again later",
              color: "bg-red-500",
            };
            this.$emit("status", data);
          }
        );
    },
    // https://api.lipalink.com/glacierproductsltd/organization/mpesa/transaction/key/v1/generate
    generateMPesaTransactionUrl() {
      this.loading = true;
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .post(
          `${this.$route.params.organization}/organization/mpesa/transaction/key/v1/generate`,
          qs.stringify(this.data),
          options
        )
        .then(
          (res) => {
            this.loading = false;
            let data = {
              msg: "API key has been generated",
              color: "bg-green-500",
            };
            this.$emit("status", data);
            this.getThisOrganization();
            this.organizationData.APIKey = res.data.APIKey;
            this.SyncToERPAPiKey = true;
          },
          () => {
            this.loading = false;
            let data = {
              msg: "An error occured please try again later",
              color: "bg-red-500",
            };
            this.$emit("status", data);
          }
        );
    },
    generateIAndMCallbackKey() { },
    generateIAndMApiKey() {
      this.loading = true;
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .post(
          `${this.$route.params.organization}/organization/api/key/generate`,
          qs.stringify(this.data),
          options
        )
        .then(
          (res) => {
            this.loading = false;
            let data = {
              msg: "API key has been generated",
              color: "bg-green-500",
            };
            this.$emit("status", data);
            this.getThisOrganization();
            this.organizationData.APIKey = res.data.APIKey;
            this.SyncToERPAPiKey = true;
          },
          () => {
            this.loading = false;
            let data = {
              msg: "An error occured please try again later",
              color: "bg-red-500",
            };
            this.$emit("status", data);
          }
        );
    },
    //  https://api.lipalink.com/glacierproductsltd/organization/im/transaction/key/v1/generate

    generateIMTransactionKey() {
      this.loading = true;
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .post(
          `${this.$route.params.organization}/organization/im/transaction/key/v1/generate`,
          qs.stringify(this.data),
          options
        )
        .then(
          (res) => {
            this.loading = false;
            let data = {
              msg: "API key has been generated",
              color: "bg-green-500",
            };
            this.$emit("status", data);
            this.getThisOrganization();
            this.organizationData.APIKey = res.data.APIKey;
            this.SyncToERPAPiKey = true;
          },
          () => {
            this.loading = false;
            let data = {
              msg: "An error occured please try again later",
              color: "bg-red-500",
            };
            this.$emit("status", data);
          }
        );
    },


    copyIAndMCallbackKey(text) {
      if (this.IAndMCallbackKeyTextCopied) {
        this.IAndMCallbackKeyTextCopied = false;
      }
      navigator.clipboard.writeText(text);
      this.IAndMCallbackKeyTextCopied = true;
      setTimeout(() => {
        this.IAndMCallbackKeyTextCopied = false;
      }, 3000);
    },
    copyIMTransactionUrl(text) {
      if (this.IMTransactionTextCopied) {
        this.IMTransactionTextCopied = false;
      }
      navigator.clipboard.writeText(text);
      this.IMTransactionTextCopied = true;
      setTimeout(() => {
        this.IMTransactionTextCopied = false;
      }, 3000);
    },

    copyUpdateMPesaTransactionUrl(text) {
      if (this.MpesaUpdateTransactionTextCopied) {
        this.MpesaUpdateTransactionTextCopied = false;
      }
      navigator.clipboard.writeText(text);
      this.MpesaUpdateTransactionTextCopied = true;
      setTimeout(() => {
        this.MpesaUpdateTransactionTextCopied = false;
      }, 3000);
    },
    copyMPesaTransactionUrl(text) {
      if (this.MpesaTransactionTextCopied) {
        this.MpesaTransactionTextCopied = false;
      }
      navigator.clipboard.writeText(text);
      this.MpesaTransactionTextCopied = true;
      setTimeout(() => {
        this.MpesaTransactionTextCopied = false;
      }, 3000);
    },
    copyIMUpdateTransactionUrl(text) {
      if (this.IMUpdateTransactionTextCopied) {
        this.IMUpdateTransactionTextCopied = false;
      }
      navigator.clipboard.writeText(text);
      this.IMUpdateTransactionTextCopied = true;
      setTimeout(() => {
        this.IMUpdateTransactionTextCopied = false;
      }, 3000);
    },
    copyMPesaCallbackUrl(text) {
      if (this.MpesaCallbackUrlTextCopied) {
        this.MpesaCallbackUrlTextCopied = false;
      }
      navigator.clipboard.writeText(text);
      this.MpesaCallbackUrlTextCopied = true;
      setTimeout(() => {
        this.MpesaCallbackUrlTextCopied = false;
      }, 3000);
    },

    copyMPesaTransactionAPIKey(text) {
      if (this.MpesaTransactionApiTextCopied) {
        this.MpesaTransactionApiTextCopied = false;
      }
      navigator.clipboard.writeText(text);
      this.MpesaTransactionApiTextCopied = true;
      setTimeout(() => {
        this.MpesaTransactionApiTextCopied = false;
      }, 3000);
    },
    copyIMTransactionAPIKey(text) {
      if (this.IAndMTextCopied) {
        this.IAndMTextCopied = false;
      }
      navigator.clipboard.writeText(text);
      this.IAndMTextCopied = true;
      setTimeout(() => {
        this.IAndMTextCopied = false;
      }, 3000);
    },
    update(value) {
      this.loading = true;
      var data;

      switch (value) {
        case "erp":
          data = {
            OrganizationId: this.organizationData.Id,
            OrganizationName: this.organizationData.OrganizationName,
            OrganizationAddress: this.organizationData.OrganizationAddress,
            Contact: this.organizationData.Contact,
            Email: this.organizationData.Email,
            SendMPesaAcknowledgement:
              this.organizationData.SendMPesaAcknowledgement,
            SyncMPesaTransactions: this.organizationData.SyncMPesaTransactions,
            SendIMAcknowledgement: this.organizationData.SendIMAcknowledgement,
            SyncIMTransaction: !this.organizationData.SyncIMTransaction,
            EnableIMConnect: this.organizationData.EnableIMConnect,
            IMCallbackAPIKey: this.organizationData.IMCallbackAPIKey,
            MPesaTransactionUrl: this.organizationData.MPesaTransactionUrl,
            UpdateMPesaTransactionUrl: this.organizationData.UpdateMPesaTransactionUrl,
            IMTransactionUrl: this.organizationData.IMTransactionUrl,
            UpdateIMTransactionUrl: this.organizationData.UpdateIMTransactionUrl,
            IMCallbackUrl: this.organizationData.IMCallbackUrl
          };
          break;
        case "sms":
          data = {
            OrganizationId: this.organizationData.Id,
            OrganizationName: this.organizationData.OrganizationName,
            OrganizationAddress: this.organizationData.OrganizationAddress,
            Contact: this.organizationData.Contact,
            Email: this.organizationData.Email,
            SendAcknowledgementSMS:
              !this.organizationData.SendAcknowledgementSMS,
            SyncToERP: this.organizationData.SyncToERP,
          };
          break;
        default:
          break;
      }
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .put(
          `${this.$route.params.organization}/organization/update`,
          qs.stringify(data),
          options
        )
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};

const data = {
  Id: "bee5386019cb48e19919d9965a012a10",
  OrganizationName: "Glacier Products Ltd",
  OrganizationAddress: "NAIROBI, ROAD A,",
  Contact: "254111107000",
  Email: "isaac.karanja@dairyland.co.ke",
  SendMPesaAcknowledgement: false,
  SyncMPesaTransactions: false,
  SyncIMTransaction: false,

  logo: "https://api.lipalink.com/file/ba8b80902d8747a2bfe8abaf76627ec9.jpg",

  MPesaAcknowledgment:
    "https://api.lipalink.com/file/a515ab2ede5c4c1e929602e30b91505b.pdf",

};
data
</script>

<style scoped>
.center-h-v {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* For WebKit browsers (e.g., Chrome, Safari) */
.custom-scrollbar::-webkit-scrollbar {
  height: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #CBD5E0;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #F7FAFC;
}

.custom-scrollbar::-webkit-scrollbar:horizontal {
  height: 6px;
  width: 1rem;
}
</style>