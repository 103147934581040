<template>
  <div class="pt-2 relative">
    <div v-if="loading" class="absolute inset-0 bg-white bg-opacity-50 z-40">
      <loader class="absolute center-h-v" />
    </div>
    <div class="flex justify-between pb-2">
      <ve-pagination
        v-if="!loading"
        :page-size="pageInformation.pageSize"
        :total="pageInformation.totalCount"
        :layout="['sizer']"
        :pageSizeOption="[1, 5, 10, 20]"
        @on-page-size-change="pageSizeChange"
      />
      <form
        v-if="!loading"
        @submit.prevent="search"
        class="border-0.5 flex items-center justify-between text-xs border-inputBorder p-1 mr-2 rounded-sm"
      >
        <input
          v-model="searchTag"
          class="focus:outline-none"
          placeholder="search"
        />
        <button type="submit">
          <img
            class="h-4 object-contain cursor-pointer"
            src="@/assets/icons/search.png"
          />
        </button>
      </form>
    </div>
    <ve-table
      class="p-3"
      :columns="columns"
      :table-data="tableData"
      :row-style-option="rowStyleOption"
      :border-x="true"
      :border-y="true"
      :sort-option="sortOption"
      :columnHiddenOption="columnHiddenOption"
    />

    <div class="p-2">
      <ve-pagination
        v-if="!loading"
        :total="pageInformation.totalCount"
        :page-index="pageInformation.currentPage"
        :page-size="pageInformation.pageSize"
        :layout="['prev', 'pager', 'next']"
        @on-page-number-change="pageNumberChange"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import loader from "../loader/loader.vue";
//var qs = require("qs");
export default {
  name: "OrganizationsTable",
  components: { loader },
  props: ["hasOrganizationsListChanged"],
  data() {
    return {
      rowStyleOption: {
        stripe: true,
      },
      searchTag: "",
      pageIndex: 1,
      pageSize: 10,
      sortOption: {
        sortChange: (params) => {
          this.sortData(params);
        },
      },
      columnHiddenOption: {
        // default hidden column keys
        defaultHiddenColumnKeys: [],
      },
      columns: [
        {
          field: "",
          key: "numbering",
          title: "#",
          width: 50,
          align: "center",
          renderBodyCell: ({ rowIndex }) => {
            return (this.pageIndex - 1) * this.pageSize + rowIndex + 1;
          },
        },
        {
          field: "OrganizationName",
          key: "a",
          title: "Organization Name",
          align: "left",
          renderBodyCell: ({ row }) => {
            return (
              <p
                on-click={() => this.openReview(row)}
                class="hover:underline text-blue-500 cursor-pointer"
              >
                {row.OrganizationName}
              </p>
            );
          },
        },
        {
          field: "",
          key: "cx",
          title: "Edit",
          align: "center",
          renderBodyCell: ({ row }) => {
            return (
              <p
                on-click={() => this.editRow(row)}
                class=" text-blue-500 cursor-pointer font-bold"
              >
                <span>&#9998;</span>
              </p>
            );
          },
        },
        {
          field: "OrganizationAddress",
          key: "b",
          title: "Organization Address",
          align: "left",
        },
        {
          field: "logo",
          key: "e",
          title: "Organization Logo",
          align: "left",
          renderBodyCell: ({ row, column }) => {
            const value = row[column.field];
            return (
              <img class="h-8 w-8 object-contain" src={`${value}`} alt="logo" />
            );
          },
        },
        {
          field: "SendAcknowledgementSMS",
          key: "c",
          title: "Send Acknowledgement SMS",
          align: "center",
          renderBodyCell: ({ row, column }) => {
            const value = row[column.field];
            return value ? (
              <input
                type="checkbox"
                id="SendAcknowledgementSMS"
                name="SendAcknowledgementSMS"
                disabled
                checked
              />
            ) : (
              <input
                type="checkbox"
                id="SendAcknowledgementSMS"
                name="SendAcknowledgementSMS"
              />
            );
          },
        },
        {
          field: "SyncToERP",
          key: "d",
          title: "Sync To ERP",
          align: "center",
          renderBodyCell: ({ row, column }) => {
            const value = row[column.field];
            return value ? (
              <input
                type="checkbox"
                id="SyncToERP"
                name="SyncToERP"
                checked
                disabled
              />
            ) : (
              <input type="checkbox" id="SyncToERP" name="SyncToERP" disabled />
            );
          },
        },
        {
          field: "Action",
          key: "cc",
          title: "Action",
          align: "left",
          renderBodyCell: ({ row }) => {
            switch (row.Status) {
              case "Approved":
                return <p class="text-green-500">{row.Status}</p>;
              case "Pending":
                return (
                  <div>
                    <button
                      on-click={() => this.openReview(row)}
                      class="cursor-pointer text-xs bg-buttonColor text-white p-1 rounded"
                    >
                      Review
                    </button>
                  </div>
                );

              default:
                break;
            }
          },
        },
      ],
      tableData: [],
      loading: false,
      registeringCallback: false,
      pageInformation: null,
      sortObj: {
        column: "OrganizationName",
        order: "desc",
      },
    };
  },
  computed: {
    ...mapGetters(["getToken", "getUser"]),
  },
  watch: {
    hasOrganizationsListChanged: function (val) {
      if (val) {
        this.getOrganizations(this.pageIndex, this.pageSize, this.sortObj);
      }
    },
  },
  created() {
    this.getOrganizations(this.pageIndex, this.pageSize, this.sortObj);
    if (this.getUser.role == "LipaLink Administrator") {
      this.columnHiddenOption.defaultHiddenColumnKeys = ["d", "c", "cx"];
    }
    if (this.getUser.role == "Company Administrator") {
      this.columnHiddenOption.defaultHiddenColumnKeys = ["cc"];
    }
  },
  methods: {
    sortData(params) {
      const keys = Object.keys(params);
      let sortObj;
      const isEmpty = Object.values(params).every(
        (x) => x === null || x === ""
      );
      if (!isEmpty) {
        keys.forEach((key) => {
          if (params[key] !== "") {
            sortObj = {
              column: key.toLowerCase(),
              order: params[key],
            };
          }
        });
      } else {
        sortObj = {
          column: "shortcode",
          order: "asc",
        };
      }

      this.getOrganizations(this.pageIndex, this.pageSize, sortObj);
    },
    search() {
      this.getOrganizations(this.pageIndex, this.pageSize, this.sortObj);
    },
    pageSizeChange(size) {
      this.pageIndex = 1;
      this.getOrganizations(
        this.pageInformation.pageNumber,
        size,
        this.sortObj
      );
      this.pageSize = size;
    },
    pageNumberChange(number) {
      this.getOrganizations(
        number,
        this.pageInformation.pageSize,
        this.sortObj
      );
      this.pageIndex = number;
    },
    editRow(row) {
      this.$emit("editOrganization", row);
    },
    openReview(row) {
      this.$emit("openReview", row);
    },
    getOrganizations() {
      this.tableData = [];
      this.loading = true;
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .get(
          `${this.$route.params.organization}/organization/query?sortString=OrganizationName&order=desc&pagingparametermodel.pageNumber=1&pagingparametermodel._pageSize=200&pagingparametermodel.pageSize=200`,
          options
        )
        .then(
          (response) => {
            response.data.Organizations.forEach((shortcode) => {
              this.tableData.push(shortcode);
            });
            this.pageInformation = response.data.PageInformation;
            this.loading = false;
          },
          () => {
            this.loading = false;
            //this.error = true;
          }
        );
    },
  },
};
</script>
