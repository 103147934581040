<template>
  <div>
    <transition name="fade">
      <div class="p-5" v-if="loading">
        <div class="h-96 placeholderLoader rounded-lg"></div>
      </div>
      <div v-else class="p-3">
        <div>
          <div class="flex justify-start text-sm items-center pl-2">
            <div class="flex text-xs items-center border-1.5">
              <div @click="changeSortingTag(tag)" v-for="(tag, index) in sortingTags" :key="index" :class="{
                'bg-buttonColor text-white': selectedSortingTag == tag,
              }" class="cursor-pointer p-1 w-16 text-center bg-white capitalize">
                {{ tag }}
              </div>
            </div>
            <div>
              <select v-if="selectedSortingTag == 'monthly'" v-model="selectedMonth"
                class="p-1 border-t-1.5 w-20 border-b-1.5 border-r-1.5 text-xs">
                <option v-for="(month, index) in months" :key="index" class="capitalize">
                  {{ month }}
                </option>
              </select>
              <!-- <input
                class="p-1 border-t-1.5 w-20 border-b-1.5 border-r-1.5 text-xs"
                v-model="selectedYear"
              /> -->
              <select v-model="selectedYear" class="p-1 border-t-1.5 w-20 border-b-1.5 border-r-1.5 text-xs">
                <option v-for="(year, index) in years" :key="index">
                  {{ year }}
                </option>
              </select>
            </div>
          </div>
          <chart :options="chartOptions" :height="140" :chartdata="chartdata" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Chart from "./chart.vue";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "Graph",
  components: { Chart },
  data() {
    return {
      loading: false,
      sortingTags: ["yearly", "monthly"],
      selectedSortingTag: "monthly",
      selectedYear: new Date().getFullYear(),
      selectedMonth: new Date().toLocaleString("default", { month: "long" }),
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      years: [],
      chartOptions: {
        // responsive: true,
        // maintainAspectRatio: false,
        scales: {},
      },

      chartdata: {
        labels: [],
        datasets: [
          {
            type: "bar",
            label: "Revenue",
            backgroundColor: "#3D3D3D",
            data: [],
            order: 2,
          },
          {
            label: "Transactions",
            type: "line",
            borderColor: "#F2A112",
            fill: false,
            data: [],
            order: 1,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["getToken"]),
  },
  created() {
    this.getGraphData();
    this.generateYears();
  },
  watch: {
    selectedSortingTag: function () {
      this.getGraphData();
    },
    selectedMonth: function () {
      this.getGraphData();
    },
    selectedYear: function (val) {
      if (val.length == 4) {
        this.getGraphData();
      }
    },
  },
  methods: {
    changeSortingTag(tag) {
      this.selectedSortingTag = tag;
    },
    generateYears() {
      const startYear = 2000;
      const endYear = startYear + 100;
      for (let i = startYear; i <= endYear; i++) {
        this.years.push(i);
      }
    },
    getGraphData() {
      this.loading = true;
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      var url;
      switch (this.selectedSortingTag) {
        case "yearly":
          url = `${this.$route.params.organization}/dashboard/graph/${this.selectedSortingTag}/${this.selectedYear}/0`;
          break;
        case "monthly":
          var month = this.months.indexOf(this.selectedMonth) + 1;
          url = `${this.$route.params.organization}/dashboard/graph/${this.selectedSortingTag}/${this.selectedYear}/${month}`;
          break;
        default:
          break;
      }
      axios.get(url, options).then(
        (response) => {
          this.loading = false;
          switch (this.selectedSortingTag) {
            case "yearly":
              this.chartdata.labels = response.data.map((month) => month.Month);
              break;
            case "monthly":
              this.chartdata.labels = response.data.map((day) => day.Day);
              break;
            default:
              break;
          }
          this.chartdata.datasets[0].data = response.data.map(
            (month) => month.Revenue
          );
          this.chartdata.datasets[1].data = response.data.map(
            (month) => month.Transaction
          );
        },
        () => {
          this.loading = false;
        }
      );
    },
  },
};
</script>