<template>
  <div class="relative" style="min-height: 50px">
    <div v-if="loading" class="absolute inset-0 bg-black bg-opacity-10 rounded-b z-50">
      <loader class="absolute center-h-v" />
    </div>
    <div v-if="organizationData" class="grid grid-cols-1 sm:grid-cols-2 divide-x-0.5">
      <div class="flex flex-col gap-5 p-5">
        <a class="text-buttonColor text-sm" :href="organizationData.CompanyRegistration" target="_blank">Company
          Registration &#10064;</a>
        <a class="text-buttonColor text-sm" :href="organizationData.CR12" target="_blank">CRI2 &#10064;</a>
        <a class="text-buttonColor text-sm" :href="organizationData.MPesaAcknowledgment" target="_blank">Mpesa
          Acknowledgement &#10064;</a><a class="text-buttonColor text-sm" :href="organizationData.BusinessPermit"
          target="_blank">Business Permit &#10064;</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Loader from "../loader/loader.vue";
var qs = require("qs");
export default {
  name: "OrganizationDocuments",
  components: {
    Loader,
  },
  data: function () {
    return {
      loading: false,
      textCopied: false,
      SyncToERPAPiKey: false,
      organizationData: null,
    };
  },
  computed: {
    ...mapGetters(["getToken"]),
  },
  created() {
    this.getThisOrganization();
  },
  methods: {
    getThisOrganization() {
      this.loading = true;
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .get(
          `${this.$route.params.organization}/organization/request`,
          options,
          this.organizationData
        )
        .then((res) => {
          this.loading = false;
          this.organizationData = res.data;
          if (this.organizationData.APIKey) {
            this.SyncToERPAPiKey = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },


    update(value) {
      this.loading = true;
      var data;
      switch (value) {
        case "erp":
          data = {
            OrganizationId: this.organizationData.Id,
            OrganizationName: this.organizationData.OrganizationName,
            OrganizationAddress: this.organizationData.OrganizationAddress,
            Contact: this.organizationData.Contact,
            Email: this.organizationData.Email,
            SendAcknowledgementSMS:
              this.organizationData.SendAcknowledgementSMS,
            SyncToERP: !this.organizationData.SyncToERP,
          };
          break;
        case "sms":
          data = {
            OrganizationId: this.organizationData.Id,
            OrganizationName: this.organizationData.OrganizationName,
            OrganizationAddress: this.organizationData.OrganizationAddress,
            Contact: this.organizationData.Contact,
            Email: this.organizationData.Email,
            SendAcknowledgementSMS:
              !this.organizationData.SendAcknowledgementSMS,
            SyncToERP: this.organizationData.SyncToERP,
          };
          break;
        default:
          break;
      }
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .put(
          `${this.$route.params.organization}/organization/update`,
          qs.stringify(data),
          options
        )
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.center-h-v {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

a.text-buttonColor {
  width: max-content;
}
</style>
